import stringUtils from "@/utilities/stringUtils";
import {
    v4
} from "uuid";
import {
    ref,
    getDownloadURL,
    uploadBytes,
    deleteObject
} from "firebase/storage";
import {
    storage
} from "../configs/firebase";
import axios from "axios";
import { db } from "@/configs/firebase";
import { collection, setDoc, getDocs, getDoc, deleteDoc, doc, query, where, getCountFromServer, startAt, limit, orderBy  } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, setPersistence, browserSessionPersistence, signOut   } from "firebase/auth";

let removeFileFromFilebase = async (path) => {
    // Create a reference to the file to delete
    const desertRef = ref(storage, path);

    deleteObject(desertRef).then(() => {
        // File deleted successfully
        return true
      }).catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error);
        return false
      });
}

let uploadFileToFirebase = async (_file) => {
    //PREPARE UPLOADING
    let fileName = `sp_${Date.now()}_${v4()}.${stringUtils.getFilenameExtension(
      _file.name
    )}`;
    const storageRef = ref(storage, `images/${fileName}`);

    //UPLOADING
    let uploadedPath;
    await uploadBytes(storageRef, _file).then((snapshot) => {
        uploadedPath = snapshot.metadata.fullPath;
    });
    return uploadedPath;
}

let getFriebaseFileUrl = async (path) => {
    let res;
    await getDownloadURL(ref(storage, path))
        .then((url) => {
            res = url;
        })
        .catch((error) => {
            error;
            res = null;
        });
    return res;
}

let getFriebaseFile = async (path) => {
    let fileUrl, fileType;
    await getDownloadURL(ref(storage, path))
        .then((url) => {
            fileUrl = url;
        })
        .catch((error) => {
            error;
            fileUrl = null;
        });

    let getDetailFilePath = fileUrl.split("?alt=")[0];
    await axios
        .get(getDetailFilePath)
        .then((res) => {
            fileType = res.data["contentType"];
        })
        .catch((err) => {
            err;
            fileType = null;
        });
    return [fileUrl, fileType];
}


let uploadFilesToFirebase = async (files) => {
    let uploadedFilenames = [];
    for (let file of files) {
        let uploadedFilename = await uploadFileToFirebase(file);
        if (uploadedFilename != null) {
            uploadedFilenames.push(uploadedFilename);
        }
    }
    return uploadedFilenames;
}

let getDbCollection = async (collectionName) => {
    let datas = []
    let fbnDatas = await getDocs(query(collection(db, collectionName)));
    fbnDatas.forEach((doc) => {
      datas.push({...doc.data(), 'id' : doc.id})
    });
    return datas
}

let getDbCollectionPagination = async (collectionName, order, start) => {
    console.log(start);
    try {
        let datas = []  
        let fbnDatas = await getDocs(query(collection(db, collectionName), orderBy(order), startAt(start), limit(10)));
        fbnDatas.forEach((doc) => {
          datas.push({...doc.data(), 'id' : doc.id})
        });
        return datas
        
    } catch (error) {
        console.log(error);
    }
}

let countDocsCollection = async (collectionName) => {
    try {
        console.log(collectionName);
        let snapshot = await getCountFromServer(collection(db, collectionName));
        return snapshot.data().count
    } catch (error) {
        console.log(error);
    }
}

let getBhBySeri = async (seriNumber, phone) => {
    try {
        let datas = []
    let fbnDatas = await getDocs(query(collection(db, 'bao-hanh'), where("seri_number", "==", seriNumber)));
    fbnDatas.forEach((doc) => {
      datas.push({...doc.data(), 'id' : doc.id})
    });
    if(datas[0].phone.toString() === phone)
        return datas[0]
    return null
    } catch (error) {
        return null
    }
}

let getDbById = async (collectionName, id) => {
    const docRef = doc(db, collectionName, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data()
}

let signInByPassword = async(email, password) => {
    const auth = getAuth();
    await setPersistence(auth, browserSessionPersistence)
    await signInWithEmailAndPassword(auth, email, password)
}

let getUserRole = async(uid) => {
    let q = query(collection(db, "user"), where("uid", "==", uid));
    let user = [];
    let querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        user.push(doc.data())
    });
    return user[0].role
}

let getCurrentUser = () => {
    return getAuth().currentUser
}

let logOut = async()=> {
    let auth = getAuth()
    signOut(auth).then(() => {
        console.log('Sign-out successful.')

      }).catch((error) => {
        // An error happened.
        console.log('Log out failed ', error);
      });
}

let editRecord = async(collection, id, newData) => {
    console.log('newData', newData);
    await setDoc(doc(db, collection, id), newData)
}

let removeRecord = async(collection, id) => {
    await deleteDoc(doc(db, collection, id))
}

let searchBHBySerialNumber = async (seriNumber) => {
    try {
        let datas = []
        let fbnDatas = await getDocs(query(collection(db, 'bao-hanh'), where("seri_number", "==", seriNumber)));
        fbnDatas.forEach((doc) => {
      datas.push({...doc.data(), 'id' : doc.id})
    });
    return datas
    } catch (error) {
        return null
    }
    
}

let searchBHByPhone = async (phone) => {
    try {
        let datas = []
        let fbnDatas = await getDocs(query(collection(db, 'bao-hanh'), where("phone", "==", phone)));
        fbnDatas.forEach((doc) => {
      datas.push({...doc.data(), 'id' : doc.id})
    });
    return datas
    } catch (error) {
        return null
    }    
}

let searchGBHBySerialNumber = async (seriNumber) => {
    try {
        let datas = []
        let fbnDatas = await getDocs(query(collection(db, 'gui-bao-hanh'), where("seri_number", "==", seriNumber)));
        fbnDatas.forEach((doc) => {
      datas.push({...doc.data(), 'id' : doc.id})
    });
    return datas
    } catch (error) {
        return null
    }
    
}

let traCuuGuiBaoHanh = async (phone, seriNumber) => {
    try {
        let datas = []
        let fbnDatas = await getDocs(query(collection(db, 'gui-bao-hanh'), where("phone", "==", phone), where("seri_number", "==", seriNumber), where("done", "==", false) ));
        fbnDatas.forEach((doc) => {
      datas.push({...doc.data(), 'id' : doc.id})
    });
    return datas
    } catch (error) {
        return null
    }    
}

let searchGBH = async (phone, seriNumber) => {
    try {
        let datas = []
        let fbnDatas = await getDocs(query(collection(db, 'gui-bao-hanh'), where("phone", "==", phone), where("seri_number", "==", seriNumber) ));
        fbnDatas.forEach((doc) => {
      datas.push({...doc.data(), 'id' : doc.id})
    });
    return datas
    } catch (error) {
        return null
    }    
}

export default {
    uploadFileToFirebase: uploadFileToFirebase,
    removeFileFromFilebase: removeFileFromFilebase,
    getFriebaseFileUrl: getFriebaseFileUrl,
    getFriebaseFile: getFriebaseFile,
    uploadFilesToFirebase: uploadFilesToFirebase,
    getDbCollection: getDbCollection,
    getDbById: getDbById,
    signInByPassword: signInByPassword,
    getUserRole: getUserRole,
    getCurrentUser: getCurrentUser,
    logOut: logOut,
    editRecord: editRecord,
    removeRecord: removeRecord,
    getBhBySeri: getBhBySeri,
    searchBHBySerialNumber: searchBHBySerialNumber,
    searchBHByPhone: searchBHByPhone,
    searchGBHBySerialNumber: searchGBHBySerialNumber,
    countDocsCollection: countDocsCollection,
    getDbCollectionPagination: getDbCollectionPagination,
    traCuuGuiBaoHanh: traCuuGuiBaoHanh,
    searchGBH: searchGBH    
}