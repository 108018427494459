<template>
  <div class="services-menu">
    <ul>
      <li @click="goto(`HDSD`)">
        <div class="sv-image">
          <img src="@/assets/img/ABS-SSM02.jpg" alt="" />
        </div>
        <div class="sv-label">
          <p class="label-text-1">Hướng dẫn</p>
          <p class="label-text-2">Sử dụng máy</p>
          <!-- <p class="label-text-3">Model ABS - SSM02</p> -->
        </div>
      </li>
      <li @click="goto(`InsurancePage`)">
        <div class="sv-image">
          <img src="@/assets/img/chinh-sasch-bao-hanh.jpg" alt="" />
        </div>
        <div class="sv-label">
          <p class="label-text-1">Chính sách</p>
          <p class="label-text-2">Bảo hành</p>
        </div>
      </li>
      <li @click="goto(`ToolPage`)">
        <div class="sv-image">
          <img src="@/assets/img/tool-text2stl.png" alt="" />
        </div>
        <div class="sv-label">
          <p class="label-text-1">Công cụ</p>
          <p class="label-text-2">Viết chữ 3D</p>
          <p class="label-text-1">Lên mặt bánh</p>
        </div>
      </li>
      <li>
        <a
          href="https://www.baizhongyun.cn/home/buildingonline-stamp"
          target="_blank"
        >
          <div class="sv-image">
            <img src="@/assets/img/cake-raw.png" alt="" />
          </div>
          <div class="sv-label">
            <p class="label-text-1">Công cụ</p>
            <p class="label-text-2">Tạo hình vẽ</p>
            <!-- <p class="label-text-1">Lên mặt bánh</p> -->
          </div></a
        >
      </li>
      <li @click="goto(`ColorPalettePage`)">
        <div class="sv-image">
          <img src="@/assets/img/color-palette.png" alt="" />
        </div>
        <div class="sv-label">
          <p class="label-text-1">Tra cứu</p>
          <p class="label-text-2">Bảng màu</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ServicesMenu",
  methods: {
    goto(_ssv) {
      this.$router.push({ name: _ssv });
    },
  },
};
</script>

<style lang="scss" scoped>
.services-menu {
  position: relative;
  background-color: #fff;
  max-width: 100vw;
  height: 200px;
  align-items: center;
  padding: 40px 5vw 0 15vw;

  ul {
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .label-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-decoration: none;
    }

    li {
      height: 160px;
      width: 240px;
      min-width: 200px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      border-radius: 10px;
      cursor: pointer;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        width: 100% !important;
        height: 100% !important;
        text-decoration: none;
        gap: 4px;

        .sv-image {
          display: flex;
          align-items: center;
          flex-shrink: 0;

          img {
            height: 160px;
            width: 120px;
            border-radius: 10px;
          }
        }
      }

      .sv-image {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        img {
          height: 160px;
          width: 120px;
          border-radius: 10px;
        }
      }

      .sv-label {
        align-self: flex-start;
        margin: 10px 2px 2px 2px;

        p {
          color: #26787c;
          margin: 5px 0;
        }

        .label-text-1 {
          font-size: 12px;
          font-weight: 500;
        }

        .label-text-2 {
          font-size: 20px;
          font-weight: 600;
        }

        .label-text-3 {
          font-size: 10px;
          font-weight: 500;
          padding-left: 30px;
        }
      }
      -webkit-animation-name: slideInUp;
      animation-name: slideInUp;
      -webkit-animation-duration: 2s;
      animation-duration: 2s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
    @-webkit-keyframes slideInUp {
      0% {
        -webkit-transform: translateY(20%);
        transform: translateY(20%);
        visibility: visible;
      }
      20% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }
    @keyframes slideInUp {
      0% {
        -webkit-transform: translateY(20%);
        transform: translateY(20%);
        visibility: visible;
      }
      20% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }

    li:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }
}
</style>
