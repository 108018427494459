<template>
  <div
    class="w-screen h-screen flex flex-col justify-center items-center bg-abaso/10 z-0"
  >
    <div
      class="h-[80vh] w-[90vw] overflow-y-auto lg:w-[800px] border-abaso border-solid rounded-[10px] border-1 bg-white flex flex-col justify-center items-center drop-shadow-lg py-5 px-2"
    >
      <p class="w-full text-center font-bold text-2xl text-abaso">
        Đăng Ký Bảo Hành
      </p>
      <div class="mt-5 w-[90%] flex flex-col justify-center items-center">
        <div class="w-full mb-3">
          <p class="text-[18px] text-abaso font-bold px-2 py-1">
            Tên khách hàng
          </p>
          <input
            class="w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2"
            type="text"
            v-model="customerName"
          />
        </div>
        <div class="w-full mb-3">
          <p class="text-[18px] text-abaso font-bold px-2 py-1">
            Số điện thoại
          </p>
          <input
            class="w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2"
            type="text"
            v-model="phone"
          />
        </div>
        <div class="w-full mb-3">
          <p class="text-[18px] text-abaso font-bold px-2 py-1">Tên sản phẩm</p>
          <div class="w-full relative" v-click-outside="hideDropdownProduct">
            <div
              :class="[
                product == null ? 'bg-abaso/80' : 'bg-white',
                'w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2 relative cursor-pointer flex flex-row justify-between items-center',
              ]"
              @click="() => toggleDropdownProduct()"
            >
              <p
                :class="[
                  product == null
                    ? 'text-[18px] text-white font-semibold'
                    : 'text-[18px] text-abaso font-base',
                ]"
              >
                {{
                  product == null
                    ? "Chọn sản phẩm"
                    : `${product.name} ${
                        product.version ? product.version : ""
                      }`
                }}
              </p>
              <img
                class="w-4 h-4"
                :src="[
                  product == null
                    ? '/ic-arrow-down.png'
                    : '/ic-arrow-down-abaso.png',
                ]"
                alt=""
              />
            </div>
            <div
              v-if="isShowDropdownProduct"
              class="w-full h-fit z-10 max-h-40 overflow-y-auto drop-shadow-md bg-white absolute top-full"
            >
              <p
                v-for="(_product, index) in listProduct"
                :key="index"
                class="px-3 py-2 hover:bg-abaso/10 cursor-pointer"
                @click="() => onChooseProduct(_product)"
              >
                {{
                  `${_product.name} ${_product.version ? _product.version : ""}`
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="w-full mb-3">
          <p class="text-[18px] text-abaso font-bold px-2 py-1">Mua hàng tại</p>
          <div class="w-full relative" v-click-outside="hideDropdownStore">
            <div
              :class="[
                store == null ? 'bg-abaso/80' : 'bg-white',
                'w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2 relative cursor-pointer flex flex-row justify-between items-center',
              ]"
              @click="() => toggleDropdownStore()"
            >
              <p
                :class="[
                  store == null
                    ? 'text-[18px] text-white font-semibold'
                    : 'text-[18px] text-abaso font-base',
                ]"
              >
                {{ store == null ? "Chọn sản phẩm" : store.name }}
              </p>
              <img
                class="w-4 h-4"
                :src="[
                  store == null
                    ? '/ic-arrow-down.png'
                    : '/ic-arrow-down-abaso.png',
                ]"
                alt=""
              />
            </div>
            <div
              v-if="isShowDropdownStore"
              class="w-full h-fit z-10 max-h-40 overflow-y-auto drop-shadow-md bg-white absolute top-full"
            >
              <div
                v-for="(_store, index) in listStore"
                :key="index"
                class="px-3 py-2 hover:bg-abaso/10 cursor-pointer flex flex-col"
                @click="() => onChooseStore(_store)"
              >
                <p class="text-base">{{ _store.name }}</p>
                <p class="text-xs">{{ _store.address }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full mb-3">
          <p class="text-[18px] text-abaso font-bold px-2 py-1">Số seri</p>
          <input
            class="w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2"
            type="text"
            v-model="seriNumber"
          />
        </div>

        <div class="w-full mb-3">
          <p class="text-[18px] text-abaso font-bold px-2 py-1">
            Ngày mua hàng
          </p>
          <input
            ref="datePickerRef"
            class="w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2"
            type="date"
            v-model="buyDate"
            @click="onPickBuyDate"
            @change="handleBuyDateChange"
          />
        </div>
      </div>
      <button
        class="mt-6 rounded-[10px] bg-abaso px-8 py-2"
        @click="onRegister"
      >
        <p class="text-white font-bold">Đăng ký</p>
      </button>
    </div>
    <div
      v-if="inProgress"
      class="w-screen h-screen bg-[#00000050] fixed top-0 left-0 flex justify-center items-center z-20"
    >
      <p class="w-fit h-fit px-10 py-5 bg-white rounded-[10px] drop-shadow-sm">
        Đang xử lý
      </p>
    </div>
  </div>
</template>

<script>
import firebaseUtl from "@/utilities/firebase";

export default {
  name: "DkBaoHanh",
  async beforeMount() {
    this.listProduct = await firebaseUtl.getDbCollection("product");

    this.listStore = await firebaseUtl.getDbCollection("store");
  },
  data() {
    return {
      customerName: "",
      phone: "",
      seriNumber: "",
      listProduct: [],
      listStore: [],
      isShowDropdownProduct: false,
      isShowDropdownStore: false,
      product: null,
      store: null,
      buyDate: new Date(),
      inProgress: false,
    };
  },
  methods: {
    showDropdownProduct() {
      this.isShowDropdownProduct = true;
    },
    hideDropdownProduct() {
      this.isShowDropdownProduct = false;
    },
    toggleDropdownProduct() {
      this.isShowDropdownProduct = !this.isShowDropdownProduct;
    },
    onChooseProduct(_product) {
      this.product = _product;
      this.hideDropdownProduct();
    },

    showDropdownStore() {
      this.isShowDropdownStore = true;
    },
    hideDropdownStore() {
      this.isShowDropdownStore = false;
    },
    toggleDropdownStore() {
      this.isShowDropdownStore = !this.isShowDropdownStore;
    },
    onChooseStore(_store) {
      this.store = _store;
      this.hideDropdownStore();
    },
    addYears(date, years) {
      date.setFullYear(date.getFullYear() + years);
      return date;
    },
    async onRegister() {
      if (
        this.customerName.length === 0 ||
        this.phone.length < 10 ||
        this.seriNumber.length === 0
      ) {
        alert("Vui lòng nhập chính xác thông tin!");
      } else {
        if (confirm("Xác nhận đăng ký bảo hành")) {
          this.inProgress = true;
          let startTime = new Date(this.buyDate);
          let endTime = new Date(this.buyDate);
          endTime = this.addYears(endTime, 2);
          let randomChar = (Math.random() + 1).toString(36).substring(7);
          let _id = `BH_${new Date().getTime()}_${randomChar}`;
          let newData = {
            id: _id,
            customer_name: this.customerName,
            phone: this.phone,
            product_id: this.product.id,
            product_name: this.product.name,
            product_version: this.product.version,
            seri_number: this.seriNumber,
            store_id: this.store.id,
            store_name: this.store.name,
            approval: false,
            start_date: startTime,
            end_date: endTime,
          };
          await firebaseUtl
            .editRecord("bao-hanh", _id, newData)
            .then(() => {
              console.log("Abaso");
              this.inProgress = false;
              alert(
                "Quý khách đã đăng ký bảo hành.\n Kết quả sẽ được gửi qua SĐT của quý khách trong 1-3 ngày làm việc"
              );
            })
            .catch((error) => {
              console.log(`Unsuccessful returned error ${error}`);
              this.inProgress = false;
              alert("Đăng ký thất bại. Vui lòng thử lại!");
            });
        }
      }
    },
    onPickBuyDate() {
      this.$refs.datePickerRef.showPicker();
    },
    handleBuyDateChange(e) {
      this.buyDate = e.target.value;
    },
  },
};
</script>

<style></style>
