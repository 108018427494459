<template>
  <div class="blog-content">
    <h1 class="title">{{ title }}</h1>
    <div class="content">
      <p class="para">
        Bánh kem là thứ không thể thiếu trong mỗi dịp sinh nhật. Nếu trước đây,
        bạn thường đặt bánh kem ngoài hàng thì nay, bạn có thể tự làm bánh tại
        nhà, thậm chí còn có thể in hình yêu thích lên chiếc bánh đó nữa.
      </p>
      <p class="para p-bold">Nguyên liệu :</p>
      <p class="para">
        Với mỗi đứa trẻ, ngoài những ngày dành riêng cho chúng như Tết thiếu
        nhi, Tết Trung thu thì sinh nhật có lẽ là ngày được mong đợi nhất. Trong
        mỗi dịp sinh nhật, bạn thường đặt bánh kem ở ngoài về, nhưng giờ đây,
        bạn có thể tự làm bánh tại nhà, thậm chí còn
        <strong>vẽ hình lên bánh sinh nhật</strong> theo sở thích của bé nữa.
      </p>
      <p class="para">
        Với cách in hình lên bánh sinh nhật mà chúng tôi hướng dẫn dưới đây,
        chắc chắn bạn sẽ có chiếc một chiếc bánh thật ý nghĩa cho bé yêu nhà
        mình, và chắc hẳn bé sẽ rất bất ngờ với món quà ấn tượng mà ý nghĩa như
        thế này đấy.
      </p>
      <p class="para p-bold p-blue">
        Để vẽ hình lên bánh sinh nhật, bạn cần có:
      </p>
      <ul class="dash">
        <li>Cốt bánh gato đã được trát kem trắng lên toàn bộ bánh</li>
        <li>Một bản vẽ hình ảnh . Ở đây chúng tôi sử dụng bản vẽ đơn giản</li>
        <li>Giấy nến Socola nhiều màu: đen/ nâu cho tóc, hồng- vàng đậm</li>
        <li>Các dụng cụ như túi bắt kem các cỡ, mảng kính thủy tinh.</li>
      </ul>
      <p class="para p-bold">Cách làm:</p>
      <ul class="low-level">
        <li>
          <p>
            <strong>Bước 1: </strong>Chuẩn bị cốt bánh gato đã được trát kem.
            Các bạn cho bánh vào tủ lạnh để bánh được bảo quản tốt nhé!
          </p>
          <div class="img-n-cap">
            <img src="@/assets/img/blogs/002/image001.png" alt="" />
            <p class="caption">
              Bước đầu tiên, nếu muốn in hình lên bánh sinh nhật, bạn cần chuẩn
              bị cốt kem.
            </p>
          </div>
        </li>
        <li>
          <p>
            <strong>Bước 2: </strong>In ảnh cần vẽ ra trang A4. Hãy chắc chắn
            rằng hình ảnh bạn cần cho lên bánh chỉ chiếm &lt; 1/2 chiếc bánh
            thôi nhé! Vì chúng ta cần diện tích để trang trí xung quanh chiếc
            bánh nữa. Đặt tấm kính thủy tinh lên trên bản in hình ảnh.
          </p>
          <div class="img-n-cap">
            <img src="@/assets/img/blogs/002/image002.png" alt="" />
            <p class="caption">In ảnh cần vẽ ra trang A4.</p>
          </div>
        </li>
        <li>
          <p>
            <strong>Bước 3: </strong>Sau đó, bạn dán một tấm giấy nến lên phía
            trên tấm kính. Giờ đây bản vẽ của chúng ta có ba lớp: dưới cùng là
            bản vẽ – tấm kính và trên cùng là giấy nến.
          </p>
          <div class="img-n-cap">
            <img src="@/assets/img/blogs/002/image003.png" alt="" />
            <p class="caption">Dán một tấm giấy nến lên phía trên tấm kính.</p>
          </div>
        </li>
        <li>
          <p>
            <strong>Bước 4: </strong>Làm tan chảy socola các màu vàng đậm/nhạt –
            hồng – nâu/đen – trắng. Mỗi loại socola màu các bạn để trong những
            bát khác nhau nhé! Sau đó, khi socola nguội, bạn đổ mỗi màu vào mỗi
            túi bắt kem và chuẩn bị khâu vẽ.
          </p>
          <div class="img-n-cap">
            <img src="@/assets/img/blogs/002/image004.png" alt="" />
            <p class="caption">Làm tan socola các màu.</p>
          </div>
        </li>
        <li>
          <p>
            <strong>Bước 5: </strong>Bước này là thú vị nhất đấy. Bạn vẽ các
            đường nét chính của cô công chúa bằng màu nâu, ưu tiên vẽ phần tóc
            cũng bằng màu nâu nhé! Vẽ theo hình mẫu có sẵn được hình thấu qua
            tấm kính thủy tinh. Các bạn nên chú tâm bởi túi bắt kem sẽ khiến bạn
            hay bị trượt tay, và hình vẽ sẽ bị nguệch ngoạc đó nha!
          </p>
          <div class="img-n-cap">
            <img src="@/assets/img/blogs/002/image005.png" alt="" />
            <p class="caption">
              Vẽ các đường nét chính của hình bằng socola màu nâu.
            </p>
          </div>
        </li>
        <li>
          <p>
            <strong>Bước 6: </strong>Tiếp tục màu vàng nhạt cho khuôn mặt và làn
            da của công chúa, màu hồng cho đường diềm trên váy và mũ; màu trắng
            cho phần cổ áo.
          </p>
          <div class="img-n-cap">
            <img src="@/assets/img/blogs/002/image006.png" alt="" />
            <p class="caption">
              Vẽ tiếp màu vàng cho làn da và mặt. cùng màu hồng cho váy.
            </p>
          </div>
        </li>
        <li>
          <p>
            <strong>Bước 7: </strong>Màu vàng đậm sẽ là màu của chiếc váy, chiếc
            mũ nha! Các bạn phun sao cho socola phủ kín các đường nét của bản vẽ
            công chúa.
          </p>
          <div class="img-n-cap">
            <img src="@/assets/img/blogs/002/image007.png" alt="" />
            <p class="caption">Màu vàng đậm là màu của váy và mũ</p>
          </div>
        </li>
        <li>
          <p>
            <strong>Bước 8: </strong>Đặt tấm vẽ bằng socola vào ngăn đá của tủ
            lạnh ít nhất 15 phút để socola đông cứng lại. Trong thời gian chờ
            bản vẽ đông cứng, bạn trang trí đường diềm cho bánh kem bằng màu
            hồng.
          </p>
          <div class="img-n-cap">
            <img src="@/assets/img/blogs/002/image008.png" alt="" />
            <p class="caption">
              Đặt tấm vẽ bằng socola vào ngăn đá của tủ lạnh ít nhất 15 phút
            </p>
          </div>
        </li>
        <li>
          <p>
            <strong>Bước 9: </strong>Nhấc bản vẽ ra khỏi tủ lạnh và bóc nhẹ
            nhàng lớp giấy nến ra ngoài.
          </p>
          <div class="img-n-cap">
            <img src="@/assets/img/blogs/002/image009.png" alt="" />
            <p class="caption">Nhấc bản vẽ ra khỏi tủ lạnh là xong rồi.</p>
          </div>
        </li>
      </ul>
      <div class="para">
        Và bây giờ bạn chỉ việc đặt hình vẽ cô công chúa nhỏ bằng socola lên
        trên chiếc bánh thôi. Thật tuyệt đẹp phải không? Chắc hẳn ai cũng bất
        ngờ bởi cách in hình lên bánh sinh nhật vô cùng thông minh và cực đơn
        giản này đấy!
      </div>
      <div class="para">
        Chiếc bánh kem thích hợp dành cho buổi tiệc sinh nhật của các bé gái
        thích hình ảnh những cô công chúa trong phim hoạt hình. Với cách làm
        tương tự, bạn có thể làm các hình vẽ ô tô, siêu nhân hay các con vật cho
        bé trai nhé! Tùy vào sự khéo léo và sáng tạo của bạn thôi đấy!
      </div>
      <div class="img-n-cap">
        <img src="@/assets/img/blogs/002/image010.png" alt="" />
        <p class="caption">
          Chiếc bánh kem có in hình công chúa trông thật đáng yêu phải không
          nào?
        </p>
      </div>
      <p class="para">
        Đây chỉ là một trong rất nhiều mẫu
        <a class="p-bold" href="https://chonchon.vn/collections/banh-kem-ve-2d"
          >bánh kem vẽ 2d</a
        >
        mà bạn có thể tham khảo.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Blog-001",
  beforeMount() {
    document.title = "Abaso | " + this.title;
  },
  data() {
    return {
      title: "Hướng dẫn vẽ hình lên bánh sinh nhật cực đơn giản mà ấn tượng",
    };
  },
};
</script>

<style lang="scss" scoped>
strong {
  font-weight: bold;
}
.blog-content {
  width: 100vw;
  height: auto;
  min-height: 80vh;
  padding: 80px 10vw 10vh 10vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 700px) {
    padding: 80px 1rem 10vh 1rem;
  }

  .title {
    align-self: flex-start;
    font-size: 2.5rem;
    font-weight: 500;
    margin: 2rem;

    @media (max-width: 700px) {
      font-size: 1.7rem;
      margin: 0.7rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    @media (max-width: 700px) {
      padding: 0 0.5rem;
    }

    .para {
      margin-bottom: 1rem;
      text-indent: 2rem;
      line-height: 1.5rem;
      text-align: justify;
      // align-self: flex-start;
    }

    .auth {
      text-align: right;
      padding-right: 5rem;
      margin-top: 1.5rem;
    }

    .p-bold {
      font-weight: 600;
    }
    .p-blue {
      color: #0b524c;
      font-size: 1.2rem;
    }

    .step {
      font-weight: 500;
      padding-left: 2rem;
    }

    .level-1 {
      padding-left: 4rem;
      text-indent: 0 !important;
    }

    .img-n-cap {
      width: fit-content;
      min-width: 30vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0 1rem 5rem;

      img {
        height: 360px;
        width: auto;

        @media (max-width: 700px) {
          max-width: 90vw;
          height: auto;
        }
      }

      .caption {
        margin: 1rem 0;
        font-size: 0.85rem;
        margin-bottom: 1.75rem;
      }
    }

    ul {
      padding-left: 5rem;
      margin-bottom: 1rem;

      li {
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
      }
    }

    ul.dash {
      list-style-type: none;
    }

    ul.dash > li:before {
      content: "-";
      position: absolute;
      margin-left: -20px;
    }

    // low-level {
    //   padding-left: 20px;
    // }
  }
}
</style>