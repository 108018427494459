import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'

import {
    library
} from "@fortawesome/fontawesome-svg-core";

import {
    faBars,
    faSearch,
    faPlus,
    faTrash,
    faFile,
    faPencil,
    faBackward,
    faArrowLeft,
    faChevronLeft,
    faAngleDown
} from "@fortawesome/free-solid-svg-icons";

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

import VueSplide from '@splidejs/vue-splide';

import vClickOutside from "click-outside-vue3"

// import VueFbCustomerChat from 'vue-fb-customer-chat';  
library.add(faBars, faSearch, faPlus, faTrash, faFile, faPencil, faBackward, faArrowLeft, faChevronLeft, faAngleDown);






createApp(App).use(router).use(VueSplide).use(vClickOutside)
    .component('font-awesome-icon', FontAwesomeIcon).mount('#app')
    