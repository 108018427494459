<template>
  <div class="footer">
    <ul class="info">
      <li class="big-info">
        <div class="branding" @click="goHome()">
          <img src="@/assets/img/logo.png" alt="" />
        </div>
        <p class="title">CÔNG TY TNHH GIẢI PHÁP TỰ ĐỘNG HÓA LÀM BÁNH ABASO</p>
        <p>
          Chúng tôi với hi vọng mang đến các giải pháp hữu ích giúp quý khách
          hàng là yêu thích công việc làm bánh có thể dễ dàng tiếp cận công việc
          kinh doanh cửa hàng bánh một cách dễ dàng và ít tốn sức lực nhất.
        </p>
        <p class="title">© 2022 Abaso Company. All rights reserved</p>
      </li>
      <li>
        <p class="title">TÌM CHÚNG TÔI TẠI</p>
        <div class="info-w-icon">
          <img src="@/assets/img/ic-phone.png" alt="" />
          <p>03666 96 008 - 094 79 150 79</p>
        </div>
        <div class="info-w-icon">
          <img src="@/assets/img/ic-facebook.png" alt="" />
          <a href="https://www.facebook.com/abaso.vn" target="_blank"
            >www.facebook.com/abaso.vn</a
          >
        </div>
        <div class="info-w-icon">
          <img src="@/assets/img/ic-mail.png" alt="" />
          <a href="https://info@abaso.vn" target="_blank">info@abaso.vn</a>
        </div>
        <div class="info-w-icon">
          <img src="@/assets/img/ic-address.png" alt="" />
          <p>
            Địa chỉ: 02, Đường D18, KP6, Phường Phước Long B, Thành phố Thủ Đức,
            Hồ Chí Minh
          </p>
        </div>
        <iframe
          class="ggmap"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.0464434942423!2d106.78544692847616!3d10.807754936957176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317526eb1287dcd9%3A0x5a30d30159c680a!2zMiDEkC4gRDE4LCBQaMaw4bubYyBMb25nIEIsIFF14bqtbiA5LCBUaMOgbmggcGjhu5EgSOG7kyBDaMOtIE1pbmgsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1671639955886!5m2!1svi!2s"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </li>
      <li>
        <p class="title" @click="goto(`Services`)">DỊCH VỤ</p>
        <a class="href" href="/faqs">Liên hệ</a><br />
        <a class="href" href="/blogs">Bài viết</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CpnFooter",
  methods: {
    goto(_page) {
      this.$router.push({ name: _page });
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100vw;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 20px 250px 20px;

  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    list-style: none;

    .info-w-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 36px;

      img {
        height: 24px;
        margin-right: 0.5rem;
      }

      p {
        padding-top: 12px;
      }

      a {
        text-decoration: none;
        font-weight: 500;
        color: rgb(0, 57, 155);
      }
    }

    .branding {
      display: flex;
      padding: 1rem 0 3rem 0rem;

      img {
        width: 160px;
        transition: 0.5s ease all;
      }
    }

    p {
      margin-bottom: 15px;
      line-height: 1.5;

      &.title {
        font-weight: 600;
      }
    }

    .ggmap {
      width: 480px;
      height: 240px;

      @media (max-width: 900px) {
        width: 80vw;
        height: 60vw;
      }
    }

    .big-info {
      width: 30vw;
    }

    .href {
      text-decoration: none;
      color: #000;
      line-height: 2.5;
    }
  }

  @media (max-width: 900px) {
    padding: 50px 20px 50px 20px;
    .info {
      flex-direction: column;
      li {
        width: 90vw;
        margin-bottom: 30px;
        p {
          line-height: 1.2;
        }
        &.big-info {
          width: 90vw;
        }
      }
    }
  }
}
</style>