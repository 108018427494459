const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

let generateString = (_length) => {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < _length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

let getFilenameExtension = (_filename) => {
    let splitedName = _filename.split('.')
    return splitedName.length < 2 ? null : splitedName[splitedName.length - 1]
}

let formatDate = (_date) => {
    const yyyy = _date.getFullYear();
    let mm = _date.getMonth() + 1; // Months start at 0!
    let dd = _date.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return dd + '/' + mm + '/' + yyyy;
}

module.exports = {
    generateString: generateString,
    getFilenameExtension: getFilenameExtension,
    formatDate: formatDate
}