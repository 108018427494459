<template>
  <div class="app">
    <CpnNavigation />
    <router-view />
    <div
      class="zalo-chat-widget"
      data-oaid="3306145093847544030"
      data-welcome-message="Abaso có thể giúp gì cho bạn"
      data-autopopup="0"
      data-width=""
      data-height=""
    ></div>

    <div id="fb-root"></div>

    <!-- Your Plugin chat code -->
    <div id="fb-customer-chat" class="fb-customerchat"></div>

    <!-- <script src="https://sp.zalo.me/plugins/sdk.js"></script> -->
    <CpnFooter />
  </div>
</template>

<script>
import CpnNavigation from "./components/CpnNavigation.vue";
import CpnFooter from "./components/CpnFooter.vue";
export default {
  name: "App",
  components: {
    CpnNavigation,
    CpnFooter,
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "https://sp.zalo.me/plugins/sdk.js");
    document.head.appendChild(recaptchaScript);
  },
};
</script>

<style lang="scss">

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
}

.app {
  min-height: 100vh;
  width: 100vw;
  position: relative;
  // background-color: red;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;

  CpnFooter {
    z-index: 100;
  }

  .zalo-chat-widget {
    right: 20px !important;
  }
}
</style>