<template>
  <div class="hdsd">
    <!-- <div class="dropdown">
      <CpnDropdown
        label="Chọn Máy"
        :listItems="machines.map((m) => m.name)"
        svName="selectMachine"
        color="#65bcb6"
        v-on:selectMachine="selectMachine"
      />
    </div>
    <div class="content" v-if="selectedMachine != null">
      <splide class="splider" :options="options">
        <splide-slide
          class="slider"
          v-for="(img, ind) in getListMachinePhotos()"
          :key="ind"
          c
        >
          <img :src="img" alt="" />
        </splide-slide>
      </splide>
    </div> -->

    <h1 id="page-title">Hướng Dẫn Sử Dụng Máy</h1>
    <div class="manchine-name">
      <button
        v-for="(machine, ind) in machines"
        :key="ind"
        :class="{ 'checked-machine': machine.isChecked }"
        @click="onCheckMachine(ind)"
      >
        {{ machine.name }}
      </button>
    </div>
    <div class="content" v-if="selectedMachine != null">
      <splide class="splider" :options="options">
        <splide-slide
          class="slider"
          v-for="(img, ind) in getListMachinePhotos()"
          :key="ind"
        >
          <img :src="img" alt="" />
        </splide-slide>
      </splide>
    </div>
  </div>
</template>

<script>
// import { Splide, SplideSlide } from "@splidejs/vue-splide";
// import CpnDropdown from "./CpnDropdown.vue";
import jsonHDSD from "@/assets/json/hdsd.json";
export default {
  name: "HDSD",
  beforeMount() {},
  components: {
    // CpnDropdown,
    // Splide,
    // SplideSlide,
  },
  data() {
    return {
      machines: jsonHDSD,
      selectedMachine: null,
      options: {
        rewind: true,
        width: "600px",
        gap: "1rem",
        loop: true,
        padding: 0,
        arrows: false,
      },
    };
  },
  methods: {
    selectMachine(ind) {
      this.selectedMachine = ind;
    },
    getListMachinePhotos() {
      var machine = this.machines[this.selectedMachine];
      var photoFiles = [...Array(machine.amountPhoto).keys()].map((m) =>
        require(`@/assets/img/HDSD/${machine.code}-${m}.jpg`)
      );
      return photoFiles;
    },
    onCheckMachine(ind) {
      for (let index in this.machines) {
        this.machines[index].isChecked = ind == index ? true : false;
      }
      this.selectMachine(ind);
    },
  },
};
</script>

<style lang="scss" scoped>
.hdsd {
  padding-top: 80px;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;

  #page-title {
    color: #6abcb6;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
  }
  .manchine-name {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      height: 100%;
      width: 100%;
      min-width: 16rem;
      padding: 0.3rem 0.5rem;
      margin: 0 5px;
      border: 1px;
      border-radius: 10px;

      &:hover {
        box-shadow: inset -1px -1px 2px rgba(255, 255, 255, 0.6),
          inset 1px 1px 2px rgba(0, 0, 0, 0.3);
      }
    }

    .checked-machine {
      background-color: #a8e89b;
    }
  }

  .content {
    align-items: center;
    justify-content: center;

    .splider {
      // background-color: aqua;
      width: 100vw;
      padding: 3rem 0 !important;

      .slider {
        align-items: center;
        justify-content: center;
        img {
          width: 500px;

          @media (max-width: 500px) {
            width: 100vw;
          }
        }
      }
    }
  }
}
</style>