<template>
  <div class="blog-content">
    <h1 class="title">{{ title }}</h1>
    <div class="content">
      <div class="para p-bold">
        Một công ty in 3D của Nhật Bản đang nổi lên kế hoạch sản xuất socola với
        hình dáng mặt người tặng chào bán Valentine 2013.
      </div>
      <div class="para">
        Đây là một quà tặng sáng tạo cho những bạn trẻ yêu nhau. Nếu thích có
        được 1 bức tượng socola “có mặt” của chính mình, một người sẽ phải bỏ ra
        6.000 yen (khoảng 1,3 triệu VND) để quét 3D dung mạo và cho máy in công
        nghiệp 3D tạo ra mô hình nhựa.
      </div>
      <div class="para">
        Đây là thành công kế tiếp của công nghệ in 3D từ năm 2011, khi các nhà
        bác học tại trường Đại học Exeter (Anh) thiết kế thành công chiếc máy in
        công nghiệp 3D, có thể nhanh chóng tạo ra những chiếc bánh socola ngon
        lành
      </div>
      <div class="img-n-cap">
        <img src="@/assets/img/blogs/003/image001.png" alt="" />
        <img src="@/assets/img/blogs/003/image002.png" alt="" />
        <p class="caption">
          Mô hình nhựa sẽ là khuôn mẫu cho khuôn silicon để tạo ra các biến
          thiên mặt người bằng socola.
        </p>
        <img src="@/assets/img/blogs/003/image003.png" alt="" />
        <img src="@/assets/img/blogs/003/image004.png" alt="" />
        <p class="caption">
          Chiếc máy in công nghiệp 3D đầu tiên trên thế giới áp dụng nguyên liệu
          thức ăn để hình thành những món ăn.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Blog-001",
  beforeMount() {
    document.title = "Abaso | " + this.title;
  },
  data() {
    return {
      title: "Công nghệ sản xuất socola 3D cực độc",
    };
  },
};
</script>

<style lang="scss" scoped>
strong {
  font-weight: bold;
}
.blog-content {
  width: 100vw;
  height: auto;
  min-height: 80vh;
  padding: 80px 10vw 10vh 10vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 700px) {
    padding: 80px 1rem 10vh 1rem;
  }

  .title {
    align-self: flex-start;
    font-size: 2.5rem;
    font-weight: 500;
    margin: 2rem;

    @media (max-width: 700px) {
      font-size: 1.7rem;
      margin: 0.7rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    @media (max-width: 700px) {
      padding: 0 0.5rem;
    }

    .para {
      margin-bottom: 1rem;
      text-indent: 2rem;
      line-height: 1.5rem;
      text-align: justify;
      // align-self: flex-start;
    }

    .auth {
      text-align: right;
      padding-right: 5rem;
      margin-top: 1.5rem;
    }

    .p-bold {
      font-weight: 600;
    }
    .p-blue {
      color: #0b524c;
      font-size: 1.2rem;
    }

    .step {
      font-weight: 500;
      padding-left: 2rem;
    }

    .level-1 {
      padding-left: 4rem;
      text-indent: 0 !important;
    }

    .img-n-cap {
      width: fit-content;
      min-width: 60vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0 1rem 5rem;

      img {
        width: 600px;
        height: auto;
        margin-top: 1rem;

        @media (max-width: 700px) {
          max-width: 90vw;
          height: auto;
        }
      }

      .caption {
        margin: 1rem 0;
        font-size: 0.85rem;
        margin-bottom: 1.75rem;
        max-width: 480px;
        text-align: center;
      }
    }

    ul {
      padding-left: 5rem;
      margin-bottom: 1rem;

      li {
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
      }
    }

    ul.dash {
      list-style-type: none;
    }

    ul.dash > li:before {
      content: "-";
      position: absolute;
      margin-left: -20px;
    }

    // low-level {
    //   padding-left: 20px;
    // }
  }
}
</style>