<template>
  <div
    class="w-screen h-screen flex flex-col justify-center items-center bg-abaso/10 z-0 overflow-hidden"
  >
    <div
      class="h-fit max-h-[80vh] w-[90vw] overflow-y-auto lg:w-[800px] border-abaso border-solid rounded-[10px] border-1 bg-white flex flex-col justify-center items-center drop-shadow-lg py-5 px-2"
    >
      <p class="w-full text-center font-bold text-2xl text-abaso">
        Gửi Bảo Hành
      </p>
      <div class="mt-5 w-[90%] flex flex-col justify-center items-center">
        <div class="w-full mb-3">
          <p class="text-[18px] text-abaso font-bold px-2 py-1">
            Số điện thoại đăng ký bảo hành
          </p>
          <div
            class="flex flex-row w-full h-fit justify-between items-center gap-4"
          >
            <input
              class="w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2"
              type="text"
              v-model="phone"
            />
            <button
              class="rounded-[10px] bg-abaso px-4 py-2 flex-shrink-0"
              @click="search"
            >
              <p class="text-white font-bold">Tìm</p>
            </button>
          </div>
        </div>
        <span
          v-if="listInsurance.length > 0"
          class="mx-auto text-abaso text-xl font-bold mt-4"
          >Các sản phẩm đã đăng ký bảo hành</span
        >
        <div class="w-full h-[500px] overflow-auto flex flex-col gap-1 mt-3">
          <div
            v-for="(bh, index) in listInsurance"
            :key="index"
            class="w-full h-fit flex flex-col justify-center items-center hover:bg-[#65bcb620] p-2 rounded-[10px] gap-1"
          >
            <div class="w-full h-fit flex flex-row justify-center items-center">
              <div class="w-[50%] h-fit">
                <span class="text-left text-abaso font-semibold">{{
                  `${bh.product_name} ${
                    bh.product_version ? bh.product_version : ""
                  }`
                }}</span>
              </div>
              <div class="w-[30%] h-fit">
                <span :class="`text-left ${getTextColor(bh)} font-semibold`">{{
                  !bh.approval
                    ? "Chờ duyệt..."
                    : bh.end_date.toDate() < today
                    ? `Hết hạn ${formatDate(bh.end_date.toDate())}`
                    : `Còn đến ${formatDate(bh.end_date.toDate())}`
                }}</span>
              </div>
              <div class="w-[20%] h-fit flex">
                <button
                  :class="[
                    !bh.approval || bh.end_date.toDate() < today
                      ? 'text-white bg-gray-400'
                      : 'bg-white border-abaso hover:border-[#65bcb6] hover:bg-[#65bcb6] text-abaso hover:text-white',
                    'rounded-[10px] border-[2px] border-solid px-3 py-1 flex-shrink-0 ml-auto ',
                  ]"
                  :disabled="!bh.approval || bh.end_date.toDate() < today"
                  @click="() => showMore(bh)"
                >
                  <p class="font-bold">Gửi</p>
                </button>
              </div>
            </div>
            <div
              class="w-full h-fit flex flex-col"
              v-if="selected && selected.id === bh.id"
            >
              <span v-if="infoGBH != null" class="text-abaso">{{
                `Sản phẩm của bạn đã được gửi bảo hành. Dự kiến hoàn thành vào ngày ${formatDate(
                  infoGBH.ngay_hoan_thanh.toDate()
                )}`
              }}</span>
              <div
                v-if="infoGBH == null"
                class="w-full h-fit flex flex-col gap-2"
              >
                <span class="text-left text-[#65bcb6] font-medium">
                  Mô tả tình trạng máy khi gửi bảo hành
                </span>
                <textarea
                  class="resize-none w-full border-abaso border-solid rounded-[10px] border-2 px-3 py-1"
                  rows="5"
                  v-model="detail"
                ></textarea>
                <button
                  class="rounded-[10px] bg-abaso px-8 py-2 mx-auto"
                  @click="submit"
                >
                  <p class="text-white font-bold">Gửi bảo hành</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="inProgress"
      class="w-screen h-screen bg-[#00000050] fixed top-0 left-0 flex justify-center items-center z-20"
    >
      <p class="w-fit h-fit px-10 py-5 bg-white rounded-[10px] drop-shadow-sm">
        Đang xử lý
      </p>
    </div>
  </div>
</template>

<script>
import stringUtils from "@/utilities/stringUtils";
import firebaseUtl from "@/utilities/firebase";

export default {
  name: "GuiBaoHanh",
  async beforeMount() {
    this.today = new Date();
  },
  data() {
    return {
      phone: "",
      listInsurance: [],
      inProgress: false,
      today: null,
      selected: null,
      infoGBH: null,
      detail: "",
    };
  },
  methods: {
    formatDate(_date) {
      return stringUtils.formatDate(_date);
    },
    getTextColor(bh) {
      if (bh.approval) {
        if (bh.end_date.toDate() < this.today) {
          return "text-red-500";
        } else {
          return "text-[#3c9da1]";
        }
      } else {
        return "text-orange-500";
      }
    },
    async search() {
      this.inProgress = true;
      let _data = await firebaseUtl.searchBHByPhone(this.phone);
      this.inProgress = false;
      if (Array.isArray(_data)) {
        if (_data.length === 0) {
          alert("Không tìm thấy!");
        } else {
          this.listInsurance = _data;
        }
      } else {
        alert("Không tìm thấy!");
      }
    },
    async showMore(bh) {
      console.log(bh);
      this.selected = bh;
      let listGBH = await firebaseUtl.searchGBHBySerialNumber(bh.seri_number);
      let info = listGBH.filter((x) => x.done === false);
      if (info.length === 0) this.infoGBH = null;
      else this.infoGBH = info[0];
    },

    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },

    async submit() {
      this.inProgress = true;
      try {
        let dateTime = new Date();
        let randomChar = (Math.random() + 1).toString(36).substring(7);
        let id = `GBH_${dateTime.getTime()}_${randomChar}`;
        let du_kien_tra = new Date();
        du_kien_tra = this.addDays(du_kien_tra, 30);

        let newData = {
          id: id,
          phone: this.selected.phone,
          seri_number: this.selected.seri_number,
          product_id: this.selected.product_id,
          product_name: this.selected.product_name,
          product_version: this.selected.product_version,
          client_note: this.detail,
          ngay_gui: new Date(),
          tiep_nhan: false,
          tiep_nhan_note: "",
          kiem_tra: false,
          kiem_tra_note: "",
          sua_chua: false,
          sua_chua_note: "",
          ban_giao: false,
          ban_giao_note: "",
          abaso_note: "",
          ngay_hoan_thanh: du_kien_tra,
          done: false,
        };

        await firebaseUtl.editRecord("gui-bao-hanh", id, newData);
        alert(
          `Gửi bảo hành thành công. Thời gian dự kiến trả vào ngày ${this.formatDate(
            du_kien_tra
          )}`
        );
        this.listInsurance = [];
        this.inProgress = false;
      } catch (error) {
        console.log(error);
        alert("Thao tác thất bại. Vui lòng thử lại sau.");
        this.inProgress = false;
      }
    },
  },
};
</script>

<style></style>
