<template>
  <div
    class="w-screen h-fit flex flex-col justify-center items-center bg-white z-0 overflow-hidden"
  >
    <div
      class="w-full max-w-[1200px] h-fit flex flex-row flex-wrap justify-start items-center pt-1 gap-3"
    >
      <input
        type="text"
        class="w-50 h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
        placeholder="Tìm bằng SĐT"
        v-model="inputSearchByPhone"
      />
      <input
        type="text"
        class="w-50 h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
        placeholder="Tìm bằng số seri"
        v-model="inputSearchBySeriNumber"
      />
      <button
        class="w-fit h-fit px-3 py-2 bg-[#65bcb6] text-white font-bold rounded-[10px]"
        @click="search()"
      >
        Tìm
      </button>
      <button
        class="w-fit h-fit px-3 py-2 bg-[#fbb648] text-white font-bold rounded-[10px]"
        @click="refresh()"
      >
        Refresh
      </button>
    </div>
    <div class="w-full max-w-[1200px] h-fit flex flex-row py-5">
      <div
        class="w-1/3 h-[1000px] border border-solid border-abaso/30 bg-abaso/5 p-2 gap-2 rounded-l-lg flex flex-col justify-start items-center overflow-auto"
      >
        <div
          v-for="(item, index) in listGBH"
          :key="index"
          :class="[
            item.id === selected.id ? 'bg-[#eefcecda]' : 'bg-white',
            'w-full h-[224px] drop-shadow-md border border-solid border-abaso/50 rounded-lg  cursor-pointer flex flex-col p-3 gap-2',
          ]"
          @click="() => onSelect(item)"
        >
          <span class="text-xl font-medium text-abaso one-line">
            {{
              `${item.product_name} ${
                item.product_version ? item.product_version : ""
              }`
            }}
          </span>
          <span>
            Số seri: <b>{{ ` ${item.seri_number}` }}</b>
          </span>
          <span>
            SĐT Khách hàng: <b>{{ ` ${item.phone}` }}</b>
          </span>
          <span>
            Ngày gửi: <b>{{ ` ${formatDate(item.ngay_gui.toDate())}` }}</b>
          </span>
          <span>
            Khách miêu tả: <b>{{ ` ${item.client_note}` }}</b>
          </span>
          <div
            class="w-full h-fit flex flex-row justify-start items-center gap-2"
          >
            <span
              :class="[
                getCurrentStatus(item) === 0
                  ? 'bg-red-500'
                  : getCurrentStatus(item) === 1
                  ? 'bg-orange-500'
                  : getCurrentStatus(item) === 2
                  ? 'bg-yellow-400'
                  : getCurrentStatus(item) === 3
                  ? 'bg-green-500'
                  : 'bg-sky-400',
                'w-fit px-3 py-1 rounded-md text-white font-medium',
              ]"
              >{{
                getCurrentStatus(item) === 0
                  ? "Tiếp nhận"
                  : getCurrentStatus(item) === 1
                  ? "Kiểm tra"
                  : getCurrentStatus(item) === 2
                  ? "Sửa chữa"
                  : getCurrentStatus(item) === 3
                  ? "Bàn giao"
                  : "Hoàn thành"
              }}</span
            >
            <span class="one-line">{{
              getCurrentStatus(item) === 0
                ? "Chờ xử lý..."
                : getCurrentStatus(item) === 1
                ? item.tiep_nhan_note
                : getCurrentStatus(item) === 2
                ? item.kiem_tra_note
                : getCurrentStatus(item) === 3
                ? item.sua_chua_note
                : item.ban_giao_note
            }}</span>
          </div>
        </div>
      </div>
      <div
        class="w-2/3 h-[1000px] border border-l-0 border-solid border-abaso/30 rounded-r-lg flex flex-col justify-start overflow-auto"
      >
        <!-- Tiep Nhan -->
        <span
          class="text-abaso text-lg font-bold w-full h-fit px-2 py-1 rounded-tr-lg cursor-pointer bg-[#99e98a]"
        >
          Tiếp nhận
        </span>
        <div
          class="w-full h-fit relative p-4 pt-1 bg-white flex flex-col gap-2"
        >
          <span class="text-left text-[#65bcb6] font-medium">
            Mô tả chi tiết khi nhận máy
          </span>
          <textarea
            class="resize-none w-full border-abaso border-solid rounded-[10px] border-2 px-3 py-1"
            rows="5"
            v-model="tiepNhanNote"
          ></textarea>
          <div
            class="flex flex-row justify-center items-center w-full h-fit gap-3"
          >
            <button
              class="px-3 py-1 rounded-lg bg-abaso text-white font-medium"
              @click="() => nextStep(0)"
            >
              Tiếp theo
            </button>
          </div>
          <div
            v-if="selected.tiep_nhan"
            class="w-full h-full absolute left-0 top-0 rounded-tr-lg bg-[#00000025] z-20"
          ></div>
        </div>
        <!-- Kiem tra -->
        <span
          class="text-abaso text-lg font-bold w-full h-fit px-2 py-1 rounded-tr-lg cursor-pointer bg-[#99e98a]"
        >
          Kiểm tra
        </span>
        <div
          class="w-full h-fit relative p-4 pt-1 bg-white flex flex-col gap-2"
        >
          <span class="text-left text-[#65bcb6] font-medium">
            Mô tả kết quả kiểm tra
          </span>
          <textarea
            class="resize-none w-full border-abaso border-solid rounded-[10px] border-2 px-3 py-1"
            rows="5"
            v-model="kiemTraNote"
          ></textarea>
          <div
            class="flex flex-row justify-center items-center w-full h-fit gap-3"
          >
            <button
              class="px-3 py-1 rounded-lg bg-white text-abaso border-[2px] border-solid border-abaso font-medium"
              @click="() => backStep(1)"
            >
              Quay lại
            </button>
            <button
              class="px-3 py-1 rounded-lg bg-abaso text-white font-medium"
              @click="() => nextStep(1)"
            >
              Tiếp theo
            </button>
          </div>
          <div
            v-if="
              selected.kiem_tra || (!selected.kiem_tra && !selected.tiep_nhan)
            "
            class="w-full h-full absolute left-0 top-0 rounded-tr-lg bg-[#00000025] z-20"
          ></div>
        </div>
        <!-- Sua chua -->
        <span
          class="text-abaso text-lg font-bold w-full h-fit px-2 py-1 rounded-tr-lg cursor-pointer bg-[#99e98a]"
        >
          Sửa chữa
        </span>
        <div
          class="w-full h-fit relative p-4 pt-1 bg-white flex flex-col gap-2"
        >
          <span class="text-left text-[#65bcb6] font-medium">
            Mô tả kết quả sửa chữa
          </span>
          <textarea
            class="resize-none w-full border-abaso border-solid rounded-[10px] border-2 px-3 py-1"
            rows="5"
            v-model="suaChuaNote"
          ></textarea>
          <div
            class="flex flex-row justify-center items-center w-full h-fit gap-3"
          >
            <button
              class="px-3 py-1 rounded-lg bg-white text-abaso border-[2px] border-solid border-abaso font-medium"
              @click="() => backStep(2)"
            >
              Quay lại
            </button>
            <button
              class="px-3 py-1 rounded-lg bg-abaso text-white font-medium"
              @click="() => nextStep(2)"
            >
              Tiếp theo
            </button>
          </div>
          <div
            v-if="
              selected.sua_chua || (!selected.sua_chua && !selected.kiem_tra)
            "
            class="w-full h-full absolute left-0 top-0 rounded-tr-lg bg-[#00000025] z-20"
          ></div>
        </div>
        <!-- Bàn giao -->
        <span
          class="text-abaso text-lg font-bold w-full h-fit px-2 py-1 rounded-tr-lg cursor-pointer bg-[#99e98a]"
        >
          Bàn giao
        </span>
        <div
          class="w-full h-fit relative p-4 pt-1 bg-white flex flex-col gap-2"
        >
          <span class="text-left text-[#65bcb6] font-medium">
            Mô tả quá trình bàn giao
          </span>
          <textarea
            class="resize-none w-full border-abaso border-solid rounded-[10px] border-2 px-3 py-1"
            rows="5"
            v-model="banGiaoNote"
          ></textarea>
          <div
            class="flex flex-row justify-center items-center w-full h-fit gap-3"
          >
            <button
              class="px-3 py-1 rounded-lg bg-white text-abaso border-[2px] border-solid border-abaso font-medium"
              @click="() => backStep(3)"
            >
              Quay lại
            </button>
            <button
              class="px-3 py-1 rounded-lg bg-abaso text-white font-medium"
              @click="() => nextStep(3)"
            >
              Tiếp theo
            </button>
            <div
              v-if="
                selected.ban_giao || (!selected.ban_giao && !selected.sua_chua)
              "
              class="w-full h-full absolute left-0 top-0 rounded- tr-lg bg-[#00000025] z-20"
            ></div>
          </div>
        </div>
        <!-- Xác nhận -->
        <span
          class="text-abaso text-lg font-bold w-full h-fit px-2 py-1 rounded-tr-lg cursor-pointer bg-[#99e98a]"
        >
          Xác nhận
        </span>
        <div
          class="flex flex-row justify-center items-center w-full h-fit gap-3 p-4 relative"
        >
          <button
            class="px-3 py-1 rounded-lg bg-sky-400 text-white text-xl font-medium"
            @click="() => backStep(4)"
          >
            Chỉnh sửa
          </button>
          <button
            class="px-3 py-1 rounded-lg bg-red-400 text-white text-xl font-medium"
            @click="() => onFinish()"
          >
            Hoàn tất
          </button>
          <div
            v-if="selected.done"
            class="w-full h-full absolute left-0 top-0 rounded-tr-lg bg-[#00000025] z-20"
          ></div>
        </div>
      </div>
    </div>
    <div
      v-if="inProgress"
      class="w-screen h-screen bg-[#00000005] fixed top-0 left-0 flex justify-center items-center z-20"
    >
      <p class="w-fit h-fit px-10 py-5 bg-white rounded-[10px] drop-shadow-sm">
        Đang xử lý
      </p>
    </div>
  </div>
</template>

<script>
import stringUtils from "@/utilities/stringUtils";
import firebaseUtl from "@/utilities/firebase";

export default {
  name: "AdminGuiBaoHanh",
  async beforeMount() {
    try {
      this.inputSearchBySeriNumber = "";
      this.inputSearchByPhone = "";
      this.listGBH = await firebaseUtl.getDbCollection("gui-bao-hanh");
      this.listShow = JSON.parse(JSON.stringify(this.listGBH));
      this.selected = this.listGBH[0];
      this.tiepNhanNote = this.listGBH[0].tiep_nhan_note;
      this.kiemTraNote = this.listGBH[0].kiem_tra_note;
      this.suaChuaNote = this.listGBH[0].sua_chua_note;
      this.banGiaoNote = this.listGBH[0].ban_giao_note;

      console.log(this.listGBH);
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      listGBH: [],
      listShow: [],
      selected: null,
      inProgress: false,
      tiepNhanNote: "",
      kiemTraNote: "",
      suaChuaNote: "",
      banGiaoNote: "",
    };
  },

  methods: {
    formatDate(_date) {
      return stringUtils.formatDate(_date);
    },

    async refresh() {
      try {
        this.listGBH = await firebaseUtl.getDbCollection("gui-bao-hanh");
      } catch (error) {
        console.log(error);
      }
    },

    async search() {
      let _data = await firebaseUtl.searchGBH(
        this.inputSearchByPhone,
        this.inputSearchBySeriNumber
      );

      if (Array.isArray(_data)) {
        // this.listGBH = JSON.parse(JSON.stringify(_data));
        this.listGBH = [..._data];
        if (_data.length === 0) {
          alert("Không tìm thấy!");
        }
      } else {
        alert("Không tìm thấy!");
      }
    },

    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },

    getCurrentStatus(item) {
      if (item.ban_giao) {
        return 4;
      } else if (item.sua_chua) {
        return 3;
      } else if (item.kiem_tra) {
        return 2;
      } else if (item.tiep_nhan) {
        return 1;
      } else return 0;
    },
    async nextStep(stepIndex) {
      let newData = { ...this.selected };
      if (stepIndex === 0) {
        if (this.tiepNhanNote.length === 0) {
          alert("Vui lòng nhập mô tả!");
          return;
        }
        newData.tiep_nhan = true;
        newData.tiep_nhan_note = this.tiepNhanNote;
      } else if (stepIndex === 1) {
        if (this.kiemTraNote.length === 0) {
          alert("Vui lòng nhập mô tả!");
          return;
        }
        newData.kiem_tra = true;
        newData.kiem_tra_note = this.kiemTraNote;
      } else if (stepIndex === 2) {
        if (this.suaChuaNote.length === 0) {
          alert("Vui lòng nhập mô tả!");
          return;
        }
        newData.sua_chua = true;
        newData.sua_chua_note = this.suaChuaNote;
      } else if (stepIndex === 3) {
        if (this.banGiaoNote.length === 0) {
          alert("Vui lòng nhập mô tả!");
          return;
        }
        newData.ban_giao = true;
        newData.ban_giao_note = this.banGiaoNote;
      }
      this.inProgress = true;
      await firebaseUtl.editRecord("gui-bao-hanh", this.selected.id, newData);
      this.listGBH = await firebaseUtl.getDbCollection("gui-bao-hanh");
      this.selected = this.listGBH.filter((x) => x.id === this.selected.id)[0];
      this.tiepNhanNote = this.selected.tiep_nhan_note;
      this.kiemTraNote = this.selected.kiem_tra_note;
      this.suaChuaNote = this.selected.sua_chua_note;
      this.banGiaoNote = this.selected.ban_giao_note;
      this.inProgress = false;
    },
    async backStep(stepIndex) {
      if (confirm("Xác nhận quay lại bước trước đó?")) {
        let newData = { ...this.selected };
        if (stepIndex === 1) {
          newData.tiep_nhan = false;
        } else if (stepIndex === 2) {
          newData.kiem_tra = false;
        } else if (stepIndex === 3) {
          newData.sua_chua = false;
        } else if (stepIndex === 4) {
          newData.ban_giao = false;
        }
        this.inProgress = true;
        await firebaseUtl.editRecord("gui-bao-hanh", this.selected.id, newData);
        this.listGBH = await firebaseUtl.getDbCollection("gui-bao-hanh");
        this.selected = this.listGBH.filter(
          (x) => x.id === this.selected.id
        )[0];
        this.tiepNhanNote = this.selected.tiep_nhan_note;
        this.kiemTraNote = this.selected.kiem_tra_note;
        this.suaChuaNote = this.selected.sua_chua_note;
        this.banGiaoNote = this.selected.ban_giao_note;
        this.inProgress = false;
      }
    },
    onSelect(item) {
      this.selected = { ...item };
      this.tiepNhanNote = item.tiep_nhan_note;
      this.kiemTraNote = item.kiem_tra_note;
      this.suaChuaNote = item.sua_chua_note;
      this.banGiaoNote = item.ban_giao_note;
    },
    async onFinish() {
      if (
        confirm(
          "Xác nhận hoàn thành sửa chữa bảo hành?\nHành động này không thể hoàn tác."
        )
      ) {
        this.inProgress = true;
        let newData = { ...this.selected };
        newData.done = true;
        await firebaseUtl.editRecord("gui-bao-hanh", this.selected.id, newData);
        this.inProgress = false;
        alert(
          `Bạn đã hoàn thành bảo hành cho sản phẩm ${
            this.selected.product_name
          } ${
            this.selected.product_version ? this.selected.product_version : ""
          }`
        );
        this.listGBH = await firebaseUtl.getDbCollection("gui-bao-hanh");
        this.selected = this.listGBH[0];
        this.tiepNhanNote = this.listGBH[0].tiep_nhan_note;
        this.kiemTraNote = this.listGBH[0].kiem_tra_note;
        this.suaChuaNote = this.listGBH[0].sua_chua_note;
        this.banGiaoNote = this.listGBH[0].ban_giao_note;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.one-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
