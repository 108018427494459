<template>
  <div class="blog-content">
    <h1 class="title">{{ title }}</h1>
    <div class="content">
      <p class="para">
        Với những ai yêu thích bánh kem, ngoài việc làm sao để có được chiếc
        bánh thơm ngon chất lượng thì khâu trang trí cũng cực kỳ quan trọng để
        bánh trở nên bắt mắt, ấn tượng, đặc biệt là công đoạn viết những thông
        điệp bằng chữ viết. Nếu vẫn còn chưa tự tin với việc này, hãy cùng tham
        khảo ngay kỹ thuật viết chữ trên bánh kem khá đơn giản mà trường Trung
        cấp CET hướng dẫn dưới đây nhé!
      </p>
      <p class="para">
        Những dịp sinh nhật, thôi nôi, đám cưới… thường không thể thiếu đi chiếc
        bánh kem xinh đẹp và thơm ngon. Trên những chiếc bánh kem ấy bao giờ
        cũng có những dòng chữ đơn giản, ngắn gọn nhưng gửi gắm rất nhiều tình
        cảm, thông điệp ý nghĩa. Và làm sao để trình bày những dòng chữ ấy thật
        đầy đủ và đẹp mắt chính là nhiệm vụ quan trọng của người làm bánh kem.
        Với kỹ thuật viết chữ trên bánh kem, bạn cũng có thể tự viết viết tên
        lên bánh sinh nhật độc đáo để làm quà tặng cho người thân, bạn bè.
      </p>
      <div class="img-n-cap">
        <img src="@/assets/img/blogs/001/image001.jpg" alt="" />
        <p class="caption">
          Những thông điệp được gửi gắm bằng chữ viết trên bánh kem rất đa dạng.
          Ảnh: Internet
        </p>
      </div>
      <p class="para">
        Dụng cụ cần chuẩn bị để thực hiện kỹ thuật viết chữ trên bánh kem
      </p>
      <p class="para">
        Trước tiên, để thực hiện kỹ thuật viết chữ trên bánh kem, bạn cần chuẩn
        bị những dụng cụ sau:
      </p>
      <ul>
        <li>
          Đui bắt kem tròn: Bạn có thể chọn các loại đui bắt kem tròn có đường
          kính to nhỏ tùy thuộc vào nét chữ đậm hoặc mỏng mình muốn nhé.
        </li>
        <li>Túi tam giác để bắt bông kem.</li>
      </ul>
      <p class="para">
        Đây là những dụng cụ đơn giản và khá quen thuộc trong làm bánh. Bạn có
        thể tìm mua dễ dàng tại các cửa hàng bán dụng cụ làm bánh hoặc siêu thị,
        trung tâm thương mại…
      </p>
      <div class="img-n-cap">
        <img src="@/assets/img/blogs/001/image002.jpg" alt="" />
        <p class="caption">Túi và đui bắt kem. Ảnh: Internet</p>
      </div>
      <p class="para">Các nguyên liệu dùng để viết chữ trên bánh kem</p>
      <p class="para">
        Đi cùng với dụng cụ chắc chắn sẽ không thể thiếu được nguyên liệu để
        viết chữ lên bánh kem. Các nguyên liệu này cũng khá đơn giản mà bạn có
        thể chuẩn bị tại nhà như sau:
      </p>
      <ul>
        <li>
          Whipping Cream hoặc Topping Cream: 2 loại kem tươi này được dùng khá
          phổ biến để trang trí bánh và khi dùng để viết chữ bánh kem, bạn chỉ
          cần pha thêm màu yêu thích cho phù hợp là có thể sử dụng. Viết chữ
          bằng nguyên liệu kem tươi này cũng giúp bạn dễ thao tác và chữ viết
          trông cũng đẹp mắt.
        </li>
        <li>
          Chocolate tan chảy: Bạn có thể dùng Dark Chocolate hoặc White
          Chocolate loại không có nhân, đem hấp cách thủy hoặc cho vào quay
          trong lò vi sóng cho tan chảy và sử dụng. Loại nguyên liệu này khi
          dùng viết chữ trên bánh kem cũng rất đẹp. Không chỉ vậy, khi nét chữ
          khô se lại còn giữ được rất lâu.
        </li>
      </ul>
      <div class="img-n-cap">
        <img src="@/assets/img/blogs/001/image003.jpg" alt="" />
        <p class="caption">
          Chocolate dùng để viết chữ trên bánh kem rất đẹp. Ảnh: Internet
        </p>
      </div>
      <ul>
        <li>
          Kem bơ (Buttercream): Kem bơ cũng là một loại nguyên liệu được dùng để
          trang trí bánh khá phổ biến. Đặc biệt khi dùng nguyên liệu này để viết
          chữ bánh kem sẽ giúp bạn thu được sẽ cho nét chữ sắc nét, có độ chắc,
          bền và lâu bị chảy. Việc này sẽ giúp chữ viết trên bánh giữ được lâu
          hơn.
        </li>
      </ul>
      <p class="para">Các bước thực hiện kỹ thuật viết chữ trên bánh kem</p>
      <p class="para step">Bước 1: Pha màu thực phẩm</p>
      <p class="para level-1">
        Pha màu cho các nguyên liệu dùng để viết chữ lên bánh kem nếu bạn dùng:
        kem bơ, Whipping Cream hay Topping Cream. Bạn nên chọn các loại màu thực
        phẩm có nguồn gốc rõ ràng và an toàn với sức khỏe học cách tạo màu thực
        phẩm từ các nguyên liệu thực phẩm tự nhiên để đảm bảo nhất.
      </p>
      <div class="img-n-cap">
        <img src="@/assets/img/blogs/001/image004.jpg" alt="" />
        <p class="caption">
          Pha màu cho nguyên liệu dùng để viết chữ trên bánh kem. Ảnh: Internet
        </p>
      </div>
      <p class="para step">Bước 2: Chuẩn bị túi và đui bắt bông kem</p>
      <p class="para level-1">
        Gắn đui vào túi bắt bông kem bằng cách cắt một đoạn nhỏ ở đầu túi bắt
        bông kem, sau đó mở túi ra thành hình phễu và cho chiếc đui đã chuẩn bị
        vào trong, dùng lực đẩy đui ra sao cho chắc. Để chắc chắn hơn, bạn có
        thể dùng thêm một chiếc túi khác bọc bên ngoài.
      </p>
      <p class="para step">Bước 3: Cho kem vào túi và tiến hành bắt chữ</p>
      <p class="para level-1">
        Tiếp đó cho một lượng kem vừa đủ đã được pha màu vào túi bắt bông kem,
        dùng tay xoắn đoạn đầu trên của túi đựng để kem có thể chảy đều xuống
        dưới và làm cho túi đựng kem phồng lên như hình một cây bút. Khi viết,
        bạn vừa đưa túi bắt kem theo nét chữ vừa dùng sức của bàn tay bóp cho
        kem chảy ra đều. Tùy vào lực bóp nhẹ hay mạnh mà bạn có thể điều chỉnh
        nét chữ thanh hoặc đậm theo ý muốn.
      </p>
      <div class="img-n-cap">
        <img src="@/assets/img/blogs/001/image005.jpg" alt="" />
        <p class="caption">
          Nét chữ thanh đậm tùy vào lực bóp túi bắt kem của tay. Ảnh: Internet
        </p>
      </div>
      <p class="para">Một số lưu ý để viết chữ lên bánh kem đẹp nhất</p>
      <ul>
        <li>
          Nếu dùng nguyên liệu là Chocolate để viết chữ lên bánh kem, bạn có thể
          không cần dùng đui tròn mà chỉ cần dùng túi bắt kem cũng được. Tuy
          nhiên, nên để Chocolate đông sệt lại một chút để viết chữ dễ dàng hơn.
        </li>
        <li>
          Để đảm bảo cho chữ viết trên bánh kem được đều bà đẹp, bạn có thể tập
          viết trước trên giấy cho quen tay để khi viết trên bánh không bị sai.
          Hoặc đơn giản hơn, bạn có thể dùng một cây tăm tre và viết lên mặt kem
          trước, sau đó bắt kem theo những đường nét đã viết sẵn là được. Bạn
          cũng có thể mua giấy Paper Boar có sẵn đường viền và chữ để luyện tập
          viết cho quen thì khi viết trên bánh kem sẽ rất đẹp và thành thạo.
        </li>
        <li>
          Nếu khi viết chữ lên bánh kem và lỡ bị sai hoặc chưa ưng ý, hãy dùng
          một miếng nhựa dẻo nhỏ, dài và mỏng, uốn lại cho có độ cong rồi nhẹ
          nhàng hớt phần chữ mà bạn muốn sửa đi và viết lại là được.
        </li>
      </ul>
      <p class="para">
        Hy vọng với những thông tin và cách viết chữ trên bánh kem này đã giúp
        bạn tự tin hơn khi trang trí bánh kem và tạo ra những nét chữ hài hòa,
        đẹp mắt nhất.
      </p>
      <p class="auth">Tác giả: Hoàng Thị Diệu Xuân</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Blog-001",
  beforeMount() {
    document.title = "Abaso | " + this.title;
  },
  data() {
    return {
      title: "Cách viết chữ lên bánh sinh nhật",
    };
  },
};
</script>

<style lang="scss" scoped>
.blog-content {
  width: 100vw;
  height: auto;
  min-height: 80vh;
  padding: 80px 10vw 10vh 10vw;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 700px) {
    padding: 80px 1rem 10vh 1rem;
  }

  .title {
    align-self: flex-start;
    font-size: 2.5rem;
    font-weight: 500;
    margin: 2rem;

    @media (max-width: 700px) {
      font-size: 1.7rem;
      margin: 0.7rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100vw;
    @media (max-width: 700px) {
      padding: 0 0.5rem;
    }

    // align-items: center;

    .para {
      margin-bottom: 1rem;
      text-indent: 2rem;
      line-height: 1.5rem;
      text-align: justify;
      // align-self: flex-start;
    }

    .auth {
      text-align: right;
      padding-right: 5rem;
      margin-top: 1.5rem;
    }

    .step {
      font-weight: 500;
      padding-left: 2rem;
    }

    .level-1 {
      padding-left: 4rem;
      text-indent: 0 !important;
    }

    .img-n-cap {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        @media (max-width: 700px) {
          max-width: 90vw;
          height: auto;
        }
      }

      .caption {
        margin: 1rem 0;
        font-size: 0.85rem;
        margin-bottom: 1.75rem;
      }
    }

    ul {
      padding-left: 5rem;
      margin-bottom: 1rem;

      li {
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>