<template>
  <div class="home">
    <div class="introduction-video">
      <video
        id="Mp4Video-16130294754cba0114"
        class="video-background"
        data-type="mp4"
        src="@/assets/videos/start.mp4"
        loop=""
        muted=""
        playsinline=""
        autoplay=""
      ></video>

      <div class="text-over-video">
        <h1>Máy Hỗ Trợ Làm Bánh Tự Động</h1>
        <h2>Hỗ trợ thợ làm bánh tối đa trong tất cả công đoạn</h2>
        <a href="/products">Mua ngay</a>
      </div>
    </div>

    <introduction-page class="introduction"></introduction-page>

    <div class="cake-slider">
      <CpnCakeSlider :photoNames="listCakePaths" />
    </div>

    <ul class="product-carts-container">
      <li class="cards-info">
        <h1>Các sản phẩm nổi bật của công ty</h1>
      </li>
      <li>
        <CpnProductCard
          class="product-card"
          v-for="products in listProducts.slice(0, 3)"
          :key="products.id"
          :cardData="products"
        />
      </li>
      <li>
        <CpnProductCard
          class="product-card"
          v-for="products in listProducts.slice(3, 6)"
          :key="products.id"
          :cardData="products"
        />
      </li>
    </ul>

    <div class="blogs-container">
      <div class="blogs">
        <div class="blogs-logo">
          <!-- <img src="@/assets/img/logo_small_1.png" alt="" /> -->
          <h3>Tin tức</h3>
          <p class="blogs-slogan">
            Cùng Abaso cập nhật xu thế công nghệ <br />
            hiện đại trong làm bánh
          </p>
        </div>

        <div class="list-blogs">
          <ul class="blogs-cards">
            <li class="blogs-card" v-for="blog in listBlogs" :key="blog.id">
              <router-link
                class="nav-link"
                :to="{
                  name: 'BlogContent',
                  params: { blogId: blog.id },
                }"
              >
                <div class="blog-img shrink-0">
                  <img :src="blog.image_path" alt="" />
                </div>
                <div class="blog-texts">
                  <h3 class="title">{{ blog.title }}</h3>
                  <p class="time">{{ Date(blog.createAt) }}</p>
                  <p class="cut-content">{{ blog.subtitle }}</p>
                </div></router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IntroductionPage from "./IntroductionPage.vue";
import CpnProductCard from "./CpnProductCard.vue";
import CpnCakeSlider from "./CpnCakeSlider.vue";
// import axios from "axios";
// import backendUrl from "@/configs/backendUrl";
import firebase from "@/utilities/firebase";
export default {
  name: "HomePage",
  components: {
    IntroductionPage,
    CpnProductCard,
    CpnCakeSlider,
  },
  async beforeMount() {
    // await axios.get(backendUrl.urls.GET_HOME_PRODUCTS_PATH_FULL).then((res) => {
    //   console.log(res.data);
    //   this.listProducts = res.data;
    //   console.log(this.listProducts);
    // // });
    // await axios.get(backendUrl.urls.GET_HOME_BLOGS_PATH_FULL).then((res) => {
    //   this.listBlogs = res.data;
    // });
    // for (let blog of this.listBlogs) {
    //   blog.imgUrl = await firebase.getFriebaseFileUrl(blog.img);
    // }
    this.listBlogs = await firebase.getDbCollection("blogs");
    this.listProducts = await firebase.getDbCollection("product");
    let vid = document.getElementById("video").current;
    vid.play();
  },
  data() {
    return {
      listProducts: [],
      listBlogs: [],
      listCakePaths: [
        "WebCake01.png",
        "WebCake02.png",
        "WebCake03.png",
        "WebCake04.png",
        "WebCake05.png",
      ],
    };
  },
  methods: {
    getBlogImgUrl(id) {
      return this.listBlogs.filter((b) => b.id == id)[0].imgUrl;
    },
    getBlogId(code) {
      return "Blog-" + code;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.home {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  @media (max-width: 720px) {
    padding-top: 58px;
  }
  .introduction-video {
    position: relative;
    text-align: center;
    color: white;
    background-color: #000;
    video {
      width: 100vw;
      max-height: 100%;
      position: relative;
      top: 50%;

      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
      -webkit-animation-duration: 5s;
      animation-duration: 5s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
    @-webkit-keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .text-over-video {
      position: absolute;
      display: flex;
      width: 80vw;
      flex-direction: column;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      align-items: center;

      h1 {
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        font-size: 64px;
        font-weight: 500;
        @media (max-width: 720px) {
          font-size: 18px;
        }
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
      @-webkit-keyframes slideInUp {
        0% {
          -webkit-transform: translateY(100%);
          transform: translateY(100%);
          visibility: visible;
        }
        100% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }
      }
      @keyframes slideInUp {
        0% {
          -webkit-transform: translateY(100%);
          transform: translateY(100%);
          visibility: visible;
        }
        100% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }
      }

      h2 {
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        font-size: 28px;
        font-weight: 100;
        @media (max-width: 900px) {
          font-size: 8px;
        }
        // font-family: "Courier New", monospace;
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
        -webkit-animation-duration: 3s;
        animation-duration: 3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
      @-webkit-keyframes slideInUp {
        0% {
          -webkit-transform: translateY(80%);
          transform: translateY(80%);
          visibility: visible;
        }
        80% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }
      }
      @keyframes slideInUp {
        0% {
          -webkit-transform: translateY(80%);
          transform: translateY(80%);
          visibility: visible;
        }
        80% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }
      }

      a {
        font-size: 24px;
        margin-top: 20px;
        text-decoration: none;
        color: #fff;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        border: 2px solid #fff;
        border-radius: 8px;
        width: fit-content;
        padding: 0px 10px 5px 10px;
        -webkit-animation-name: bounceIn;
        animation-name: bounceIn;
        -webkit-animation-duration: 0.75s;
        animation-duration: 0.75s;
        -webkit-animation-duration: 3s;
        animation-duration: 3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        @media (max-width: 900px) {
          font-size: 10px;
          margin-top: 8px;
          padding: 2px 6px;
          border-radius: 2px;
          border: 1px solid #fff;
        }
      }
      @-webkit-keyframes bounceIn {
        0%,
        20%,
        40%,
        60%,
        80%,
        100% {
          -webkit-transition-timing-function: cubic-bezier(
            0.215,
            0.61,
            0.355,
            1
          );
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        0% {
          opacity: 0;
          -webkit-transform: scale3d(0.3, 0.3, 0.3);
          transform: scale3d(0.3, 0.3, 0.3);
        }
        20% {
          -webkit-transform: scale3d(1.1, 1.1, 1.1);
          transform: scale3d(1.1, 1.1, 1.1);
        }
        40% {
          -webkit-transform: scale3d(0.9, 0.9, 0.9);
          transform: scale3d(0.9, 0.9, 0.9);
        }
        60% {
          opacity: 1;
          -webkit-transform: scale3d(1.03, 1.03, 1.03);
          transform: scale3d(1.03, 1.03, 1.03);
        }
        80% {
          -webkit-transform: scale3d(0.97, 0.97, 0.97);
          transform: scale3d(0.97, 0.97, 0.97);
        }
        100% {
          opacity: 1;
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
        }
      }
      @keyframes bounceIn {
        0%,
        20%,
        40%,
        60%,
        80%,
        100% {
          -webkit-transition-timing-function: cubic-bezier(
            0.215,
            0.61,
            0.355,
            1
          );
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        0% {
          opacity: 0;
          -webkit-transform: scale3d(0.3, 0.3, 0.3);
          transform: scale3d(0.3, 0.3, 0.3);
        }
        20% {
          -webkit-transform: scale3d(1.1, 1.1, 1.1);
          transform: scale3d(1.1, 1.1, 1.1);
        }
        40% {
          -webkit-transform: scale3d(0.9, 0.9, 0.9);
          transform: scale3d(0.9, 0.9, 0.9);
        }
        60% {
          opacity: 1;
          -webkit-transform: scale3d(1.03, 1.03, 1.03);
          transform: scale3d(1.03, 1.03, 1.03);
        }
        80% {
          -webkit-transform: scale3d(0.97, 0.97, 0.97);
          transform: scale3d(0.97, 0.97, 0.97);
        }
        100% {
          opacity: 1;
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }

  .break {
    height: 10vh;
    border-top: 5px solid rgba(0, 0, 0, 0.8);
    margin: 50px 0;

    @media (max-width: 900px) {
      margin-bottom: 30px;
    }
  }

  .product-carts-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: #fff;
    padding: 60px 0;

    li {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      padding: 50px;
      justify-content: space-evenly;

      .product-card {
        position: relative;
        height: 100%;

        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }

        .product-cards {
        }

        @media (max-width: 1200px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .product-card {
            margin: 10px 0;
          }
        }
      }
    }

    .cards-info {
      width: 100%;
      text-align: center;
      padding: 40px 0 0 0;

      h1 {
        font-size: 40px;
        font-weight: 500;
        padding: 20px;
      }

      p {
        font-size: 20px;
        font-weight: lighter;
      }
    }
  }

  .blogs-container {
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    align-items: center;
    justify-content: center;

    .blogs {
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .list-blogs {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        max-width: 1380px;
        margin: 0 auto;

        .blogs-cards {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          padding: 50px 5px;
          width: 100%;

          li {
            height: 210px;
            width: 680px;
            display: flex;
            flex-direction: row;
            list-style: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.19);
            border-radius: 10px;
            margin: 1rem 1rem;
            gap: 20px;

            &:hover {
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05),
                0 6px 20px 0 rgba(0, 0, 0, 0.05);
             }

            .nav-link {
              display: flex;
              flex-direction: row;
              list-style: none;
              text-decoration: none;
              color: #000;

              .blog-img {
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  height: 200px;
                  width: 200px;
                  border-bottom: 0px;
                  border-radius: 10px;
                }
              }

              .blog-texts {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
                padding: 5px 10px;

                h3 {
                  font-size: 24px;
                  font-weight: 900;
                }

                p.time {
                  color: #666;
                  font-size: 10;
                  font-weight: 500;
                }

                p.cut-content {
                  font-size: 14px;
                  text-align: justify;
                  padding-top: 10px;
                  margin-bottom: 10px;
                }
              }
            }
          }

          @media (max-width: 900px) {
            .blogs-card {
              width: 360px;
              min-height: 420px;
              height: fit-content;
              flex-direction: column;
              justify-content: space-between;
              .nav-link {
                flex-direction: column;
              }
            }
          }
        }
      }

      .blogs-logo {
        // background-color: cornsilk;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-right: 100px;

        img {
          width: 160px;
        }

        h3 {
          font-size: 40px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        p {
          font-size: 20px;
        }
      }
    }
    @media (max-width: 900px) {
      .blogs {
        flex-direction: column;
        .blogs-logo {
          padding: 20px 0;
        }
        .blogs-cards {
          // background-color: aquamarine;
          .blogs-card {
            width: 360px;
            height: 420px;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
</style>
