<template>
  <div class="csbh">
    <div class="menu-items">
      <button
        :class="{ selected: this.selected === 0 }"
        @click="this.selected = 0"
      >
        Chính sách bảo hành
      </button>
      <button :class="{ selected: this.selected === 1 }" @click="gotoDk()">
        Đăng ký bảo hành</button
      ><button :class="{ selected: this.selected === 2 }" @click="gotoKt()">
        Tra cứu bảo hành
      </button>
    </div>
    <img src="@/assets/img/chinh-sasch-bao-hanh.jpg" />
  </div>
</template>

<script>
export default {
  name: "InsurancePage",
  data() {
    return {
      selected: 0,
    };
  },
  methods: {
    gotoDk() {
      this.$router.push({ name: "DkBaoHanhPage" });
    },
    gotoKt() {
      this.$router.push({ name: "KtBaoHanhPage" });
    },
  },
};
</script>

<style lang="scss" scoped>
.csbh {
  padding-top: 80px;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100vw;
    height: auto;
  }

  .menu-items {
    width: 100vw;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
      padding: 4px 10px;
      height: fit-content;
      width: fit-content;
      margin: 0 10px;
      border: 1px;
      border-radius: 10px;

      &:hover {
        box-shadow: inset -1px -1px 2px rgba(255, 255, 255, 0.6),
          inset 1px 1px 2px rgba(0, 0, 0, 0.3);
      }
    }
    .selected {
      background-color: #a8e89b;
    }
  }
}
</style>
