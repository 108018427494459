import {
    createRouter,
    createWebHistory
} from "vue-router"

import HomePage from "../components/HomePage.vue"
import ProductsPage from "../components/ProductsPage.vue"
import ServicesPage from "../components/ServicesPage.vue"
import BlogsPage from "../components/BlogsPage.vue"
import ContactUsPage from "../components/ContactUsPage.vue"
import ProductDetailPage from "@/components/ProductDetailPage.vue"
import AdminPage from "@/components/AdminPage.vue"
import LoginPage from "@/components/LoginPage.vue"
import HDSD from "@/components/HDSD.vue"
import InsurancePage from "@/components/InsurancePage.vue"
import ToolPage from "@/components/ToolPage.vue"
import BlogContentPage from '@/components/BlogContentPage.vue'
import Blog001 from '@/components/Blogs/Blog001.vue'
import Blog002 from '@/components/Blogs/Blog002.vue'
import Blog003 from '@/components/Blogs/Blog003.vue'
import DkBaoHanhPage from '@/components/DkBaoHanhPage.vue'
import KtBaoHanhPage from '@/components/KtBaoHanhPage.vue'
import ColorPalettePage from '@/components/ColorPalette.vue'
import GuiBaoHanhPage from '@/components/GuiBaoHanh.vue'

const routes = [{
        path: "/",
        name: "Home",
        component: HomePage,
        meta: {
            title: "Abase Home Page"
        }
    },
    {
        path: "/products",
        name: "Products",
        component: ProductsPage,
        meta: {
            title: "Abaso | Products"
        }
    },
    {
        path: "/services",
        name: "Services",
        component: ServicesPage,
        meta: {
            title: "Abaso | Services"
        }
    },
    {
        path: "/blogs",
        name: "Blogs",
        component: BlogsPage,
        meta: {
            title: "Abaso | Blogs"
        }
    },
    {
        path: "/faqs",
        name: "ContactUs",
        component: ContactUsPage,
        meta: {
            title: "Abaso | Bạn hỏi Abaso trả lời"
        }
    },
    {
        path: "/products/:productId",
        name: "ProductDetail",
        component: ProductDetailPage,
        meta: {
            title: "Product"
        }
    },
    {
        path: "/blog/:blogId",
        name: "BlogContent",
        component: BlogContentPage,
        meta: {
            title: "Blog Content"
        }
    },
    {
        path: "/admin",
        name: "Admin",
        component: AdminPage,
        meta: {
            title: "Abaso Admin"
        }
    },
    {
        path: "/login",
        name: "LogIn",
        component: LoginPage,
        meta: {
            title: "Log In"
        }
    },
    {
        path: "/huong-dan-su-dung",
        name: "HDSD",
        component: HDSD,
        meta: {
            title: "Abaso | Hướng Dẫn Sử Dụng Máy"
        }
    },
    {
        path: "/dang-ky-bao-hanh",
        name: "DkBaoHanhPage",
        component: DkBaoHanhPage,
        meta: {
            title: "Abaso | Đăng ký bảo hành"
        }
    }, {
        path: "/tra-cuu-bao-hanh",
        name: "KtBaoHanhPage",
        component: KtBaoHanhPage,
        meta: {
            title: "Abaso | Tra cứu bảo hành"
        }
    },
    {
        path: "/chinh-sach-bao-hanh",
        name: "InsurancePage",
        component: InsurancePage,
        meta: {
            title: "Abaso | Chính sách bảo hành"
        }
    },
    {
        path: "/tool-3d-text",
        name: "ToolPage",
        component: ToolPage,
        meta: {
            title: "Abaso | Công Cụ Khắc Chữ 3D Lên Mặt Bánh"
        }
    },
    {
        path: "/blog-001",
        name: "Blog-001",
        component: Blog001,
        meta: {
            title: "Blog Content"
        }
    }, {
        path: "/blog-002",
        name: "Blog-002",
        component: Blog002,
        meta: {
            title: "Blog Content"
        }
    }, {
        path: "/blog-003",
        name: "Blog-003",
        component: Blog003,
        meta: {
            title: "Blog Content"
        }
    },{
        path: "/color-palette",
        name: "ColorPalettePage",
        component: ColorPalettePage,
        meta: {
            title: "Color Palette"
        }
    },{
        path: "/gui-bao-hanh",
        name: "GuiBaoHanhPage",
        component: GuiBaoHanhPage,
        meta: {
            title: "Gửi Bảo Hành"
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({
            behavior: 'smooth'
        });
    }
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})

export default router