// const HOST = 'http://localhost'
const HOST = 'https://www.abaso.vn'
// const ${PORT} = '8001'
const PORT = '3443'

const urls = {
    LOCALHOST_URL: `${HOST}:${PORT}`,
    GET_ALL_PRODUCTS_PATH: `/all-products`,
    GET_ALL_PRODUCTS_PATH_FULL: `${HOST}:${PORT}/all-products`,
    GET_HOME_PRODUCTS_PATH: `/home-products`,
    GET_HOME_PRODUCTS_PATH_FULL: `${HOST}:${PORT}/home-products`,
    GET_PRODUCT_BY_ID_PATH: `/product?pid=<$pid>`,
    GET_PRODUCT_BY_ID_PATH_FULL: `${HOST}:${PORT}/product?pid=<$pid>`,
    GET_COUNT_SUB_BY_PID_PATH: `/subproduct/count-by-productid?pid=<$pid>`,
    GET_COUNT_SUB_BY_PID_FULL_PATH: `${HOST}:${PORT}/subproduct/count-by-productid?pid=<$pid>`,
    GET_ALL_SUB_BY_PID_PATH: `/subproduct/get-by-productid?pid=<$pid>`,
    GET_ALL_SUB_BY_PID_FULL_PATH: `${HOST}:${PORT}/subproduct/get-by-productid?pid=<$pid>`,
    CREATE_PRODUCT_PATH: `/product/`,
    CREATE_PRODUCT_FULL_PATH: `${HOST}:${PORT}/product/`,
    EDIT_PRODUCT_PATH: `/product/`,
    EDIT_PRODUCT_FULL_PATH: `${HOST}:${PORT}/product/`,
    CREATE_SUBPRODUCT_PATH: `/sub-product/`,
    CREATE_SUBPRODUCT_FULL_PATH: `${HOST}:${PORT}/sub-product/`,
    EDIT_SUBPRODUCT_PATH: `/sub-product/`,
    EDIT_SUBPRODUCT_FULL_PATH: `${HOST}:${PORT}/sub-product/`,
    DELETE_SUBPRODUCT_BY_SID_PATH: `/subproduct/remove-by-sid?sid=<$sid>`,
    DELETE_SUBPRODUCT_BY_SID_FULL_PATH: `${HOST}:${PORT}/subproduct/remove-by-sid?sid=<$sid>`,
    DELETE_SUBPRODUCT_BY_PID_PATH: `/subproduct/remove-by-pid?pid=<$pid>`,
    DELETE_SUBPRODUCT_BY_PID_FULL_PATH: `${HOST}:${PORT}/subproduct/remove-by-pid?pid=<$pid>`,
    DELETE_PRODUCT_BY_PID_PATH: `/product/remove-by-pid?pid=<$pid>`,
    DELETE_PRODUCT_BY_PID_FULL_PATH: `${HOST}:${PORT}/product/remove-by-pid?pid=<$pid>`,

    CREATE_FAQ_PATH: `/faq/`,
    CREATE_FAQ_FULL_PATH: `${HOST}:${PORT}/faq/`,
    EDIT_FAQ_PATH: `/faq/`,
    EDIT_FAQ_FULL_PATH: `${HOST}:${PORT}/faq/`,
    GET_ALL_FAQS_PATH: `/all-faqs`,
    GET_ALL_FAQS_PATH_FULL: `${HOST}:${PORT}/all-faqs`,
    SEARCH_FAQS_PATH: `/search-faq?keyword=<$keyword>`,
    SEARCH_FAQS_PATH_FULL: `${HOST}:${PORT}/search-faq?keyword=<$keyword>`,
    GET_FAQ_BY_FID_PATH: `/faq?fid=<$fid>`,
    GET_FAQ_BY_FID_FULL_PATH: `${HOST}:${PORT}/faq?fid=<$fid>`,
    DELETE_FAQ_BY_FID_PATH: `/faq?fid=<$fid>`,
    DELETE_FAQ_BY_FID_FULL_PATH: `${HOST}:${PORT}/faq?fid=<$fid>`,

    CREATE_BLOG_PATH: `/blog/`,
    CREATE_BLOG_FULL_PATH: `${HOST}:${PORT}/blog/`,
    EDIT_BLOG_PATH: `/blog/`,
    EDIT_BLOG_FULL_PATH: `${HOST}:${PORT}/blog/`,
    GET_ALL_BLOGS_PATH: `/all-blogs`,
    GET_ALL_BLOGS_PATH_FULL: `${HOST}:${PORT}/all-blogs`,
    GET_HOME_BLOGS_PATH: `/home-blogs`,
    GET_HOME_BLOGS_PATH_FULL: `${HOST}:${PORT}/home-blogs`,
    GET_BLOG_BY_BID_PATH: `/blog?bid=<$bid>`,
    GET_BLOG_BY_BID_FULL_PATH: `${HOST}:${PORT}/blog?bid=<$bid>`,
    DELETE_BLOG_BY_BID_PATH: `/blog?bid=<$bid>`,
    DELETE_BLOG_BY_BID_FULL_PATH: `${HOST}:${PORT}/blog?bid=<$bid>`,

    LOGIN_PATH: `/login/`,
    LOGIN_FULL_PATH: `${HOST}:${PORT}/login/`,
    VERITY_TOKEN_PATH: `/verity-token/`,
    VERITY_TOKEN_FULL_PATH: `${HOST}:${PORT}/verity-token/`,
}

const header = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    "Content-Type": "text/json",
}

const firebaseGS = `gs://abaso-dev.appspot.com/`

module.exports = {
    header: header,
    firebaseGS: firebaseGS,
    urls: urls
}