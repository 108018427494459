<template>
  <div
    class="flex flex-col justify-start items-center w-screen h-fit my-10 relative"
  >
    <div class="w-full max-w-[1500px] h-fit overflow-y-auto">
      <div
        class="w-full h-fit justify-start items-center mb-4 gap-3 flex flex-row flex-wrap"
      >
        <button
          class="w-fit h-fit px-3 py-2 bg-abaso text-white font-bold rounded-[10px]"
          @click="() => onRefesh()"
        >
          Refesh
        </button>
        <button
          class="w-fit h-fit px-3 py-2 bg-[#3c9da1] text-white font-bold rounded-[10px]"
          @click="() => onSwitchStoreBH()"
        >
          {{ !this.showStores ? "Danh sách cửa hàng" : "Danh sách bảo hành" }}
        </button>
        <button
          v-if="this.showStores"
          @click="this.showAddStore = true"
          class="w-fit h-fit px-3 py-2 bg-[#65bcb6] text-white font-bold rounded-[10px]"
        >
          Thêm cửa hàng
        </button>

        <div
          class="flex flex-row justify-center items-center gap-3"
          v-if="!this.showStores"
        >
          <input
            type="text"
            class="w-50 h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
            placeholder="Tìm bằng số seri"
            v-model="inputSearchBySeriNumber"
          />
          <button
            class="w-fit h-fit px-3 py-2 bg-[#65bcb6] text-white font-bold rounded-[10px]"
            @click="onSearch()"
          >
            Tìm
          </button>
        </div>
      </div>

      <div class="w-full h-fit" v-if="!showStores">
        <div
          class="grid grid-cols-12 w-full h-fit bg-[#65bcb6] rounded-t-[10px] border-solid border-[1px] border-b-abaso"
        >
          <div class="col-span-3 px-4 py-3 font-medium text-white">
            Sản phẩm
          </div>
          <div class="col-span-2 px-4 py-3 font-medium text-white">Số seri</div>
          <div class="col-span-1 px-4 py-3 font-medium text-white">SĐT</div>
          <div class="col-span-1 px-4 py-3 font-medium text-white">Tên KH</div>
          <div class="col-span-1 px-4 py-3 font-medium text-white">Nơi mua</div>
          <div class="col-span-2 px-4 py-3 font-medium text-white">
            Ngày mua
          </div>
          <div class="col-span-2 px-4 py-3 font-medium text-white">
            Trạng thái
          </div>
        </div>
        <div
          v-for="(bh, index) in dsBaoHanh"
          :key="index"
          class="grid grid-cols-12 w-full h-fit"
        >
          <div
            class="col-span-3 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{
              `${bh.product_name} ${
                bh.product_version ? bh.product_version : ""
              }`
            }}
          </div>
          <div
            class="col-span-2 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ bh.seri_number }}
          </div>
          <div
            class="col-span-1 px-4 py-3 border-solid border-[1px] border-b-abaso break-words"
          >
            {{ bh.phone }}
          </div>
          <div
            class="col-span-1 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ bh.customer_name }}
          </div>
          <div
            class="col-span-1 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ bh.store_name }}
          </div>
          <div
            class="col-span-2 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ formatDate(bh.start_date.toDate()) }}
          </div>
          <div
            class="col-span-2 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            <button
              v-if="currentItem !== bh.id"
              :class="[
                !bh.approval
                  ? 'bg-orange-500'
                  : bh.end_date.toDate() < new Date()
                  ? 'bg-red-500'
                  : 'bg-[#65bcb6]',
                'px-3 py-2 rounded-[10px] text-white font-bold',
              ]"
              @click="() => onChooseItem(bh.id)"
            >
              {{
                !bh.approval
                  ? "Chưa kích hoạt"
                  : `Exp: ${formatDate(bh.end_date.toDate())}`
              }}
            </button>
            <div
              v-if="currentItem === bh.id"
              class="w-full h-fit p-2flex flex-row flex-wrap"
            >
              <button
                v-if="bh.approval"
                class="m-1 px-3 py-2 rounded-[10px] bg-orange-500 text-white font-bold"
                @click="() => onDeactivate()"
              >
                Hủy kích hoạt
              </button>
              <button
                v-if="!bh.approval"
                class="m-1 px-3 py-2 rounded-[10px] bg-abaso text-white font-bold"
                @click="() => onActive()"
              >
                Kích hoạt
              </button>
              <button
                class="m-1 px-3 py-2 rounded-[10px] bg-red-500 text-white font-bold"
                @click="() => onRemove()"
              >
                Xóa
              </button>
              <button
                class="m-1 px-3 py-2 rounded-[10px] bg-sky-600 text-white font-bold"
                @click="() => onEdit(bh)"
              >
                Sửa đổi
              </button>
              <button
                class="m-1 px-3 py-2 rounded-[10px] bg-white border-2 border-solid border-abaso text-abaso font-bold"
                @click="() => onCancel()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full h-fit" v-if="showStores">
        <div
          class="grid grid-cols-12 w-full h-fit bg-[#65bcb6] rounded-t-[10px] border-solid border-[1px] border-b-abaso"
        >
          <div class="col-span-2 px-4 py-3 font-medium text-white">Code</div>
          <div class="col-span-3 px-4 py-3 font-medium text-white">
            Tên cửa hàng
          </div>
          <div class="col-span-4 px-4 py-3 font-medium text-white">Địa chỉ</div>
          <div class="col-span-2 px-4 py-3 font-medium text-white">SĐT</div>
          <div class="col-span-1 px-4 py-3 font-medium text-white">
            Hành động
          </div>
        </div>
        <div
          v-for="(store, index) in listStore"
          :key="index"
          class="grid grid-cols-12 w-full h-fit"
        >
          <div
            class="col-span-2 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ store.code }}
          </div>
          <div
            class="col-span-3 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ store.name }}
          </div>
          <div
            class="col-span-4 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ store.address }}
          </div>
          <div
            class="col-span-2 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ store.phone }}
          </div>
          <div
            class="col-span-1 px-4 py-3 border-solid border-[1px] border-b-abaso flex flex-row flex-wrap justify-center items-center gap-2"
          >
            <span
              class="text-abaso font-bold cursor-pointer"
              @click="() => loadStoreData(store.id)"
              >Sửa</span
            >
            <span
              class="text-red-500 font-bold cursor-pointer"
              @click="() => removeStore(store.id)"
              >Xóa</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="inProgress"
      class="w-screen h-screen bg-[#00000050] fixed top-0 left-0 flex justify-center items-center z-20"
    >
      <p class="w-fit h-fit px-10 py-5 bg-white rounded-[10px] drop-shadow-sm">
        Đang xử lý
      </p>
    </div>
    <div
      v-if="this.showAddStore"
      class="w-screen h-screen fixed left-0 top-0 bg-[#00000050] flex justify-center items-center"
    >
      <div
        class="w-[640px] h-fit py-8 px-10 overflow-auto rounded-xl bg-white drop-shadow-sm flex flex-col justify-center items-center gap-2"
      >
        <span class="w-full text-center text-2xl text-abaso font-bold mb-6"
          >Thêm cửa hàng nhận bảo hành</span
        >
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Tên cửa hàng"
          v-model="this.inputNewStoreName"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Mã code"
          v-model="this.inputNewStoreCode"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Số điện thoại"
          v-model="this.inputNewStorePhone"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Địa chỉ"
          v-model="this.inputNewStoreAddress"
        />
        <div
          class="w-full h-fit flex justify-center items-center flex-row flex-wrap gap-3 mt-6"
        >
          <button
            class="w-fit h-fit px-3 py-2 bg-red-500 text-white font-bold rounded-[10px]"
            @click="this.showAddStore = false"
          >
            Hủy</button
          ><button
            @click="() => onAddNewStore()"
            class="w-fit h-fit px-3 py-2 bg-abaso text-white font-bold rounded-[10px]"
          >
            Thêm
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="this.showEditStore"
      class="w-screen h-screen fixed left-0 top-0 bg-[#00000050] flex justify-center items-center"
    >
      <div
        class="w-[640px] h-fit py-8 px-10 overflow-auto rounded-xl bg-white drop-shadow-sm flex flex-col justify-center items-center gap-2"
      >
        <span class="w-full text-center text-2xl text-abaso font-bold mb-6"
          >Thay đổi thông tin cửa hàng nhận bảo hành</span
        >
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Tên cửa hàng"
          v-model="this.inputNewStoreName"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Mã code"
          v-model="this.inputNewStoreCode"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Số điện thoại"
          v-model="this.inputNewStorePhone"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Địa chỉ"
          v-model="this.inputNewStoreAddress"
        />
        <div
          class="w-full h-fit flex justify-center items-center flex-row flex-wrap gap-3 mt-6"
        >
          <button
            class="w-fit h-fit px-3 py-2 bg-red-500 text-white font-bold rounded-[10px]"
            @click="this.showEditStore = false"
          >
            Hủy</button
          ><button
            @click="() => onEditStore()"
            class="w-fit h-fit px-3 py-2 bg-abaso text-white font-bold rounded-[10px]"
          >
            Xác nhận
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="this.showEditInsurance"
      class="w-screen h-screen fixed left-0 top-0 bg-[#00000050] flex justify-center items-center"
    >
      <div
        class="h-[80vh] w-[90vw] overflow-y-auto lg:w-[800px] border-abaso border-solid rounded-[10px] border-1 bg-white flex flex-col justify-center items-center drop-shadow-lg py-5 px-2"
      >
        <p class="w-full text-center font-bold text-2xl text-abaso">
          Thay đổi thông tin bảo hành
        </p>
        <div class="mt-5 w-[90%] flex flex-col justify-center items-center">
          <div class="w-full mb-3">
            <p class="text-[18px] text-abaso font-bold px-2 py-1">
              Tên khách hàng
            </p>
            <input
              class="w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2"
              type="text"
              v-model="eiCustomerName"
            />
          </div>
          <div class="w-full mb-3">
            <p class="text-[18px] text-abaso font-bold px-2 py-1">
              Số điện thoại
            </p>
            <input
              class="w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2"
              type="text"
              v-model="eiPhone"
            />
          </div>
          <div class="w-full mb-3">
            <p class="text-[18px] text-abaso font-bold px-2 py-1">
              Tên sản phẩm
            </p>
            <div class="w-full relative" v-click-outside="hideDropdownProduct">
              <div
                :class="[
                  eiProduct == null ? 'bg-abaso/80' : 'bg-white',
                  'w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2 relative cursor-pointer flex flex-row justify-between items-center',
                ]"
                @click="() => toggleDropdownProduct()"
              >
                <p
                  :class="[
                    eiProduct == null
                      ? 'text-[18px] text-white font-semibold'
                      : 'text-[18px] text-abaso font-base',
                  ]"
                >
                  {{
                    eiProduct == null
                      ? "Chọn sản phẩm"
                      : `${eiProduct.name} ${
                          eiProduct.version ? eiProduct.version : ""
                        }`
                  }}
                </p>
                <img
                  class="w-4 h-4"
                  :src="[
                    eiProduct == null
                      ? '/ic-arrow-down.png'
                      : '/ic-arrow-down-abaso.png',
                  ]"
                  alt=""
                />
              </div>
              <div
                v-if="isShowDropdownProduct"
                class="w-full h-fit z-10 max-h-40 overflow-y-auto drop-shadow-md bg-white absolute top-full"
              >
                <p
                  v-for="(_product, index) in listProduct"
                  :key="index"
                  class="px-3 py-2 hover:bg-abaso/10 cursor-pointer"
                  @click="() => onChooseProduct(_product)"
                >
                  {{
                    `${_product.name} ${
                      _product.version ? _product.version : ""
                    }`
                  }}
                </p>
              </div>
            </div>
          </div>

          <div class="w-full mb-3">
            <p class="text-[18px] text-abaso font-bold px-2 py-1">
              Mua hàng tại
            </p>
            <div class="w-full relative" v-click-outside="hideDropdownStore">
              <div
                :class="[
                  eiStore == null ? 'bg-abaso/80' : 'bg-white',
                  'w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2 relative cursor-pointer flex flex-row justify-between items-center',
                ]"
                @click="() => toggleDropdownStore()"
              >
                <p
                  :class="[
                    eiStore == null
                      ? 'text-[18px] text-white font-semibold'
                      : 'text-[18px] text-abaso font-base',
                  ]"
                >
                  {{ eiStore == null ? "Chọn sản phẩm" : eiStore.name }}
                </p>
                <img
                  class="w-4 h-4"
                  :src="[
                    eiStore == null
                      ? '/ic-arrow-down.png'
                      : '/ic-arrow-down-abaso.png',
                  ]"
                  alt=""
                />
              </div>
              <div
                v-if="isShowDropdownStore"
                class="w-full h-fit z-10 max-h-40 overflow-y-auto drop-shadow-md bg-white absolute top-full"
              >
                <div
                  v-for="(_store, index) in listStore"
                  :key="index"
                  class="px-3 py-2 hover:bg-abaso/10 cursor-pointer flex flex-col"
                  @click="() => onChooseStore(_store)"
                >
                  <p class="text-base">{{ _store.name }}</p>
                  <p class="text-xs">{{ _store.address }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full mb-3">
            <p class="text-[18px] text-abaso font-bold px-2 py-1">Số seri</p>
            <input
              class="w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2"
              type="text"
              v-model="eiSeriNumber"
            />
          </div>

          <div class="w-full mb-3">
            <p class="text-[18px] text-abaso font-bold px-2 py-1">
              Ngày mua hàng
            </p>
            <input
              ref="datePickerRef"
              class="w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2"
              type="date"
              v-model="eiBuyDate"
              @click="onPickBuyDate"
              @change="handleBuyDateChange"
            />
          </div>
        </div>
        <div class="flex flex-row gap-3 justify-center items-center mx-auto">
          <button
            class="mt-6 rounded-[10px] bg-white border-[2px] border-solid border-abaso px-8 py-2"
            @click="
              () => {
                showEditInsurance = false;
              }
            "
          >
            <p class="text-abaso font-bold">Hủy</p>
          </button>
          <button
            class="mt-6 rounded-[10px] bg-abaso px-8 py-2 border-[2px] border-solid border-abaso"
            @click="changeInsurance"
          >
            <p class="text-white font-bold">Xác nhận</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseUtl from "@/utilities/firebase";
import stringUtils from "@/utilities/stringUtils";

export default {
  name: "AdminQuanLyBaoHanh",
  async beforeMount() {
    // });
    this.dsBaoHanh = await firebaseUtl.getDbCollection("bao-hanh");
    this.listStore = await firebaseUtl.getDbCollection("store");
    this.listProduct = await firebaseUtl.getDbCollection("product");
    console.log("dsBaoHanh", this.dsBaoHanh);
  },
  data() {
    return {
      dsBaoHanh: [],
      isShowPopup: false,
      currentItem: null,
      inProgress: false,
      listStore: [],
      showStores: false,
      inputSearchBySeriNumber: "",
      showAddStore: false,
      showEditStore: false,
      inputNewStoreName: "",
      inputNewStoreCode: "",
      inputNewStorePhone: "",
      inputNewStoreAddress: "",
      dataStoreEdit: null,
      selectedStoreId: "",
      showEditInsurance: false,
      eiCustomerName: "",
      eiPhone: "",
      eiSeriNumber: "",
      listProduct: [],
      isShowDropdownProduct: false,
      isShowDropdownStore: false,
      eiProduct: null,
      eiStore: null,
      eiBuyDate: "",
      eiId: "",
      eiApproval: false,
    };
  },
  methods: {
    formatDateYYYYMMDD(_date) {
      const yyyy = _date.getFullYear();
      let mm = _date.getMonth() + 1; // Months start at 0!
      let dd = _date.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      return yyyy + "-" + mm + "-" + dd;
    },
    showDropdownProduct() {
      this.isShowDropdownProduct = true;
    },
    hideDropdownProduct() {
      this.isShowDropdownProduct = false;
    },
    toggleDropdownProduct() {
      this.isShowDropdownProduct = !this.isShowDropdownProduct;
    },
    onChooseProduct(_product) {
      this.eiProduct = _product;
      this.hideDropdownProduct();
    },

    showDropdownStore() {
      this.isShowDropdownStore = true;
    },
    hideDropdownStore() {
      this.isShowDropdownStore = false;
    },
    toggleDropdownStore() {
      this.isShowDropdownStore = !this.isShowDropdownStore;
    },
    onChooseStore(_store) {
      this.eiStore = _store;
      this.hideDropdownStore();
    },
    addYears(date, years) {
      date.setFullYear(date.getFullYear() + years);
      return date;
    },

    onPickBuyDate() {
      this.$refs.datePickerRef.showPicker();
    },
    handleBuyDateChange(e) {
      console.log(e.target.value);
      this.eiBuyDate = e.target.value;
    },

    formatDate(_date) {
      return stringUtils.formatDate(_date);
    },
    onChooseItem(_id) {
      this.currentItem = _id;
    },
    onCancel() {
      this.currentItem = null;
    },
    onEdit(bh) {
      this.eiId = bh.id;
      this.eiCustomerName = bh.customer_name;
      this.eiPhone = bh.phone;
      this.eiSeriNumber = bh.seri_number;
      this.eiBuyDate = this.formatDateYYYYMMDD(bh.start_date.toDate());
      console.log(this.eiBuyDate);
      this.eiProduct = this.listProduct.filter(
        (x) => x.id === bh.product_id
      )[0];
      this.eiStore = this.listStore.filter((x) => x.id === bh.store_id)[0];
      this.eiApproval = bh.approval;

      this.showEditInsurance = true;
    },
    async onActive() {
      let newData = this.dsBaoHanh.filter((b) => b.id === this.currentItem)[0];
      if (
        confirm(
          `Xác nhận kích hoạt bảo hành cho sản phẩm có seri=${newData.seri_number}`
        )
      ) {
        this.inProgress = true;
        newData.approval = true;
        await firebaseUtl.editRecord("bao-hanh", this.currentItem, newData);
        this.dsBaoHanh = await firebaseUtl.getDbCollection("bao-hanh");
        alert("Đã kích hoạt bảo hành");
        this.currentItem = null;
        this.inProgress = false;
      }
    },
    async onDeactivate() {
      let newData = this.dsBaoHanh.filter((b) => b.id === this.currentItem)[0];
      if (
        confirm(
          `Xác nhận hủy kích hoạt bảo hành cho sản phẩm có seri=${newData.product_id}`
        )
      ) {
        this.inProgress = true;
        newData.approval = false;
        await firebaseUtl.editRecord("bao-hanh", this.currentItem, newData);
        this.dsBaoHanh = await firebaseUtl.getDbCollection("bao-hanh");
        alert("Đã hủy kích hoạt");
        this.currentItem = null;
        this.inProgress = false;
      }
    },
    async onRemove() {
      if (confirm("Xác nhận xóa Xóa?")) {
        this.inProgress = true;
        await firebaseUtl.removeRecord("bao-hanh", this.currentItem);
        alert("Đã xóa");
        this.dsBaoHanh = await firebaseUtl.getDbCollection("bao-hanh");
        this.currentItem = null;
        this.inProgress = false;
      }
    },
    async onRefesh() {
      this.dsBaoHanh = await firebaseUtl.getDbCollection("bao-hanh");
      this.listStore = await firebaseUtl.getDbCollection("store");
    },
    async onSwitchStoreBH() {
      this.showStores = !this.showStores;
    },
    async onSearch() {
      let _data = await firebaseUtl.searchBHBySerialNumber(
        this.inputSearchBySeriNumber
      );
      if (Array.isArray(_data)) {
        this.dsBaoHanh = _data;
      } else {
        alert("Không tìm thấy!");
      }
    },
    async addNewStore() {
      try {
        let newData = {
          name: this.inputNewStoreName,
          code: this.inputNewStoreCode,
          phone: this.inputNewStorePhone,
          address: this.inputNewStoreAddress,
        };

        let dateTime = new Date();
        let randomChar = (Math.random() + 1).toString(36).substring(7);
        let id = `store_${dateTime.getTime()}_${randomChar}`;
        await firebaseUtl.editRecord("store", id, newData);
      } catch (error) {
        console.log(error);
      }
    },
    async onAddNewStore() {
      try {
        this.showAddStore = false;
        await this.addNewStore();
        this.inProgress = true;
        this.inputNewStoreAddress = "";
        this.inputNewStoreCode = "";
        this.inputNewStorePhone = "";
        this.inputNewStoreName = "";
        alert("Thêm cửa hàng mới thành công!");
        this.listStore = await firebaseUtl.getDbCollection("store");
        this.inProgress = false;
      } catch (e) {
        alert("Thêm cửa hàng mới thất bại!");
        this.inProgress = false;
      }
    },
    async loadStoreData(_id) {
      this.inProgress = true;
      let storeData = this.listStore.filter((x) => x.id === _id)[0];
      this.inputNewStoreAddress = storeData.address;
      this.inputNewStoreName = storeData.name;
      this.inputNewStoreCode = storeData.code;
      this.inputNewStorePhone = storeData.phone;
      this.selectedStoreId = _id;
      this.inProgress = false;
      this.showEditStore = true;
    },
    async editStore() {
      try {
        let newData = {
          name: this.inputNewStoreName,
          code: this.inputNewStoreCode,
          phone: this.inputNewStorePhone,
          address: this.inputNewStoreAddress,
        };
        await firebaseUtl.editRecord("store", this.selectedStoreId, newData);
      } catch (error) {
        console.log(error);
      }
    },
    async onEditStore() {
      try {
        this.showEditStore = false;
        await this.editStore();
        this.inProgress = true;
        this.inputNewStoreAddress = "";
        this.inputNewStoreCode = "";
        this.inputNewStorePhone = "";
        this.inputNewStoreName = "";
        this.selectedStoreId = "";
        alert("Thay đổi thông tin cửa hàng thành công!");
        this.listStore = await firebaseUtl.getDbCollection("store");
        this.inProgress = false;
      } catch (e) {
        alert("Thay đổi thông tin cửa hàng thất bại!");
        this.inProgress = false;
      }
    },
    async removeStore(id) {
      try {
        this.inProgress = true;
        await firebaseUtl.removeRecord("store", id);
        alert("Xóa cửa hàng thành công!");
        this.listStore = await firebaseUtl.getDbCollection("store");
        this.inProgress = false;
      } catch (error) {
        alert("Xóa cửa hàng thất bại!");
        this.inProgress = false;
      }
    },
    async changeInsurance() {
      this.showEditInsurance = false;
      this.inProgress = true;
      await this.onChangeInsurance();
      this.inProgress = false;
      this.dsBaoHanh = await firebaseUtl.getDbCollection("bao-hanh");
    },
    async onChangeInsurance() {
      if (
        this.eiCustomerName.length === 0 ||
        this.eiPhone.length < 10 ||
        this.eiSeriNumber.length === 0
      ) {
        alert("Vui lòng nhập chính xác thông tin!");
      } else {
        if (confirm("Xác nhận đăng ký bảo hành")) {
          this.inProgress = true;
          let startTime = new Date(this.eiBuyDate);
          let endTime = new Date(this.eiBuyDate);
          endTime = this.addYears(endTime, 2);
          let newData = {
            id: this.eiId,
            customer_name: this.eiCustomerName,
            phone: this.eiPhone,
            product_id: this.eiProduct.id,
            product_name: this.eiProduct.name,
            product_version: this.eiProduct.version,
            seri_number: this.eiSeriNumber,
            store_id: this.eiStore.id,
            store_name: this.eiStore.name,
            approval: this.eiApproval,
            start_date: startTime,
            end_date: endTime,
          };
          await firebaseUtl
            .editRecord("bao-hanh", this.eiId, newData)
            .then(() => {
              console.log("Abaso");
              this.inProgress = false;
              alert("Thay đổi thông tin bảo hành thành công");
            })
            .catch((error) => {
              console.log(`Unsuccessful returned error ${error}`);
              this.inProgress = false;
              alert("Thay đổi thông tin bảo hành thất bại. Vui lòng thử lại!");
            });
        }
      }
    },
  },
};
</script>

<style></style>
