<template>
  <div class="page-root">
    <div
      class="flex flex-row gap-2 flex-wrap w-full h-fit justify-center items-center mb-3"
    >
      <h1 class="page-title">Bảng Màu</h1>
      <img
        class="w-auto h-14"
        src="/img/logo.54785793.png"
        alt=""
        data-v-26e577d6=""
      />
    </div>
    <div class="seach-bar">
      <input
        type="text"
        class="inp-search"
        placeholder="Tìm theo tên..."
        v-model="inputSearch"
        @input="onSearch"
      />
      <button class="btn-search" v-on:click="sendSearchContent">
        <font-awesome-icon class="icon-search" icon="search" color="#fff" />
      </button>
    </div>
    <div
      class="w-full max-w-[1280px] h-fit my-10 flex flex-row flex-wrap gap-4 justify-center items-center hover:drop-shadow-xl"
    >
      <div
        v-for="(color, index) in filteredColors"
        :key="index"
        class="flex flex-col w-[160px] h-[210px] md:w-[240px] md:h-[320px] rounded-lg border-[#00000020] border-[1px] border-solid justify-start items-center gap-1 cursor-pointer hover:drop-shadow-xl bg-white"
      >
        <img
          class="w-full h-[160px] md:h-[240px] rounded-t-lg"
          :src="color.image"
        />
        <div
          class="flex flex-col gap-0 justify-start items-center w-full h-full px-2"
        >
          <span
            class="text-[#2e787b] text-base md:text-xl font-medium one-line"
            >{{ color.vi }}</span
          >
          <span
            class="text-[#6abcb6] text-base md:text-xl font-medium one-line"
            >{{ color.eng }}</span
          >
          <p
            class="text-[#383838] text-xs md:text-sm mt-1 md:mt-[6px] two-line text-center"
          >
            {{ color.note }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseUtl from "@/utilities/firebase";
export default {
  name: "ColorPalettePage",
  data() {
    return {
      inputSearch: "",
      listColor: [],
      filteredColors: [],
    };
  },
  async beforeMount() {
    this.listColor = await firebaseUtl.getDbCollection("color-palette");
    this.filteredColors = await this.listColor;
  },
  methods: {
    onSearch() {
      this.filteredColors = this.listColor.filter(
        (x) =>
          x.vi.toLowerCase().includes(this.inputSearch.toLowerCase()) ||
          x.eng.toLowerCase().includes(this.inputSearch.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.page-root {
  padding-top: 100px;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .page-title {
    font-size: 36px;
    font-weight: 700;
    color: #26787c;
  }

  .one-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .two-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .seach-bar {
    height: 36px;
    width: 90vw;
    max-width: 500px;
    background-color: #fff;
    border-radius: 36px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 0.1875rem;

    &:focus-within {
      background-color: #fff;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 4px 8px 0 rgba(0, 0, 0, 0.19);
    }

    .inp-search {
      font-size: 14px;
      width: 90%;
      padding-left: 10px;
      border: 1px solid rgba(255, 255, 255, 0);
      border-radius: 20px;

      &:focus {
        outline: none !important;
        // background-color: rgba(0, 0, 0, 0.03);
      }
    }

    .btn-search {
      // background-color: #1e90ff;
      background-color: #fff;
      width: 36px;
      border: 1px solid #fff;
      border-radius: 20px;
      font-size: 14px;
      font-weight: bold;

      .icon-search {
        color: rgba(0, 0, 0, 0.8);
      }

      &:hover {
        background-color: #97d18c;
        .icon-search {
          color: #fff;
        }
      }

      &:active {
        box-shadow: inset -2px -2px 3px rgba(255, 255, 255, 0.6),
          inset 2px 2px 3px rgba(0, 0, 0, 0.6);
      }
    }
  }
}
</style>
