<template>
  <div class="cake-silder">
    <splide class="splider" :options="options">
      <splide-slide v-for="(photoPath, ind) in getListPhotoPaths()" :key="ind">
        <img :src="photoPath" alt="" />
      </splide-slide>
    </splide>
    <div class="text-over-silde">
      <divt class="texts">
        <h3>Với ABASO...</h3>
        <p>
          Chúng tôi luôn muốn tạo ra thật nhiều sản phẩm hữu ích hô trợ tối đa
          cho thợ làm bánh. Abaso hoàn toàn có thể chế tạo theo ý tưởng, nhu cầu
          riêng của Quý khách hàng. Hãy liên hệ ngay với chúng tôi khi bạn có
          nhu cầu chế tạo máy làm bánh.
        </p>
      </divt>
    </div>
  </div>
</template>

<script>
export default {
  name: "CakeSlider",
  beforeMount() {
  },
  props: {
    photoNames: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      options: {
        rewind: true,
        width: "100vw",
        gap: "1rem",
        loop: true,
        autoplay: true,
        arrows: false,
        // pagination: false,
        paginationDirection: "ttb",
        padding: 0,
        // role: "tab",
      },
    };
  },
  methods: {
    getListPhotoPaths() {
      let listPath = [];
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 900) {
        this.photoNames.forEach((photoName) => {
          let _photoName = photoName.split(".");
          _photoName[0] += "_16x9";
          photoName = _photoName.join(".");
          let path = require(`@/assets/img/CakeWeb/${photoName}`);
          listPath.push(path);
        });
      } else {
        this.photoNames.forEach((photoName) => {
          let path = require(`@/assets/img/CakeWeb/${photoName}`);
          listPath.push(path);
        });
      }
      return listPath;
    },
  },
};
</script>

<style lang="scss" scoped>
.cake-silder {
  position: relative;

  .splider {
    padding: 0;
  }

  .text-over-silde {
    position: absolute;
    display: flex;
    width: 360px;
    height: 240px;
    background-color: rgba(255, 255, 255, 0.8);
    top: 3%;
    right: 8%;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .texts {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 10px 20px;
      border: 2px solid #000;
      border-radius: 8px;
      width: 310px;
      height: 200px;
      align-content: center;
      justify-content: space-evenly;
      text-align: center;

      h3 {
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        font-size: 24px;
        font-weight: 500;
      }

      p {
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        text-align: center;
        font-size: 14px;
      }
    }

    @media (max-width: 900px) {
      width: 160px;
      height: 90px;

      .texts {
        width: 150px;
        height: 80px;
        padding: 5px;

        h3 {
          font-size: 10px;
        }

        p {
          font-size: 6px;
        }
      }
    }
  }

  img {
    width: 125vw;
    // height: 10vh;

    // @media (max-width: 900px) {
    //   height: auto;
    // }
  }
}
</style>