<template>
  <div class="w-screen h-fit bg-white pt-24">
    <div
      class="blog-content [&>*]:mb-2 [&>p]:text-justify [&>h1]:font-bold [&>h1]:text-4xl [&>h2]:font-semibold [&>h2]:text-3xl [&>h3]:font-medium [&>h3]:text-2xl [&>h4]:text-xl [&>h5]:text-[12px] [&>h5]:text-center [&>p>img]:mx-auto"
      ref="content"
    ></div>
  </div>
</template>

<script>
import firebaseUtils from "@/utilities/firebase";

export default {
  name: "BlogContent",
  data() {
    return {
      blogId: this.$route.params.blogId,
      blogUrl: null,
    };
  },
  async beforeMount() {
    this.product = await firebaseUtils.getDbById("blogs", this.blogId);
    const content = this.$refs.content;
    content.innerHTML = this.product.content;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.blog-content {
  width: 100vw;
  max-width: 1200px;
  height: fit-content;
  margin: 0 auto;
  padding: 32px;

  .ql-align-center {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
