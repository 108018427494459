<template>
  <div class="product-card">
    <div class="card-info">
      <div class="card-img">
        <img :src="src" />
      </div>
      <div class="info">
        <h2 class="name">
          {{ `${cardData.name} ${cardData.version ? cardData.version : ""}` }}
        </h2>
        <ul>
          <li class="prices">
            <h3 class="origin-price">
              {{ formatPrice[0] }}
            </h3>
            <h3 class="final-price">
              {{ formatPrice[1] }}
            </h3>
          </li>
          <li>
            <span class="rating">
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
            </span>
          </li>
        </ul>
      </div>

      <p></p>
    </div>

    <div class="card-description">
      <ul class="list-card-detail">
        <li v-for="(intro, ind) in cardData.intro_item" :key="ind">
          {{ intro }}
        </li>
      </ul>

      <div class="view-detail" @click="gotoProductDetail()">
        <h4 class="cta-text">Xem chi tiết</h4>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseUtils from "@/utilities/firebase";
import router from "@/router";
export default {
  async beforeMount() {
    this.src = await firebaseUtils.getFriebaseFileUrl(this.cardData.img);
  },
  name: "CpnCard",
  props: {
    cardData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      src: null,
    };
  },
  methods: {
    gotoProductDetail() {
      router.push({
        name: "ProductDetail",
        params: { productId: this.cardData.id },
      });
    },
  },
  computed: {
    formatPrice() {
      if (this.cardData.origin_price === 0 && this.cardData.final_price === 0) {
        return ["Giá tốt nhất", "Liên hệ ABASO"];
      }
      let op = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "VND",
      }).format(this.cardData.origin_price);

      let fp = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "VND",
      }).format(this.cardData.final_price);

      return [op, fp];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@splidejs/splide/dist/css/themes/splide-default.min.css";
@import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
@import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

.product-card {
  width: 400px;
  height: 100%;
  max-width: 700px;
  min-height: 580px;
  background: white;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  box-shadow: 3px 5px 6px -3px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // margin: 15px 0;
  transition: all 0.4s ease-in 0.2s;
  z-index: 100;
  &:hover {
    width: 700px;
    @media (max-height: 600) {
      width: 90vw;
    }
    .card-description {
      opacity: 1;
      right: 0%;
    }
  }

  .card-info {
    // background: red;
    transition: all 0.6s ease-in 0.2s;
    position: absolute;
    left: 0%;
    top: 0;
    bottom: 0;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-img {
      //   background-color: #d5685e;
      width: 400px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.4s ease-in 0.2s;
      img {
        width: 400px;
        height: auto;
      }
    }
    .info {
      //   background-color: #65bcb6;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .name {
        font-family: Verdana, sans-serif;
        font-size: 32px;
        font-weight: 900;
        line-height: 1;
        color: darkolivegreen;
        margin: 0;
      }

      ul {
        list-style: none;

        .prices {
          // background-color: #d5685e;

          .origin-price {
            font-family: "Roboto", sans-serif;
            color: #666;
            font-weight: 400;
            font-size: 16px;
            margin-top: 10px;
            text-decoration-line: line-through;
          }
          .final-price {
            font-family: "Roboto", sans-serif;
            color: red;
            font-weight: 600;
            font-size: 26px;
            margin-bottom: 10px;
          }
        }
      }

      .rating i {
        color: #d5685e;
        font-size: 14px;
      }
    }
  }

  .card-description {
    //background: red;
    transition: all 0.5s ease-in 0.1s;
    position: absolute;
    right: -100%;
    opacity: 0;
    top: 0;
    bottom: 0;
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    .list-card-detail {
      overflow: hidden;
      padding: 8px 0;
      gap: 8px;

      li {
        font-family: Verdana, sans-serif;
        font-weight: 100;
        line-height: 1.5;
        word-spacing: 1px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 12; /* number of lines to show */
        line-clamp: 12;
        -webkit-box-orient: vertical;
      }
    }

    p {
      text-align: center;
      padding: 0 10px;
      color: #666;
      font-weight: 300;
      font-size: 14px;
    }

    .view-detail {
      background: #65bcb6;
      padding: 8px 15px;
      margin: 8px 0 0 0;
      text-decoration: none;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      vertical-align: middle;
      &:hover {
        background: darken(#65bcb6, 10%);
      }
      .cta-text {
        margin: 0;
        color: white;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 700px) {
    background-color: #d5685e;
    width: 200px;
    height: 100%;
    max-width: 350px;
    min-height: 350px;
    background: white;
    border: 0.5px solid rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    box-shadow: 3px 5px 6px -3px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin: 15px 0;
    transition: all 0.4s ease-in 0.2s;
    z-index: 100;
    &:hover {
      width: 350px;
      .card-description {
        opacity: 1;
        right: -3%;
      }
    }

    .card-info {
      // background: red;
      transition: all 0.6s ease-in 0.2s;
      position: absolute;
      left: 0%;
      top: 0;
      bottom: 0;
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .card-img {
        //   background-color: #d5685e;
        width: 200px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s ease-in 0.2s;
        img {
          width: 200px;
          height: auto;
        }
      }
      .info {
        //   background-color: #65bcb6;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .name {
          font-family: Verdana, sans-serif;
          font-size: 20px;
          font-weight: 900;
          line-height: 1;
          color: darkolivegreen;
          margin: 0;
        }

        ul {
          list-style: none;

          .prices {
            // background-color: #d5685e;

            .origin-price {
              font-family: "Roboto", sans-serif;
              color: #666;
              font-weight: 400;
              font-size: 10px;
              margin-top: 5px;
              text-decoration-line: line-through;
            }
            .final-price {
              font-family: "Roboto", sans-serif;
              color: red;
              font-weight: 600;
              font-size: 14px;
              margin-bottom: 5px;
            }
          }
        }

        .rating i {
          color: #d5685e;
          font-size: 8px;
        }
      }
    }

    .card-description {
      transition: all 0.5s ease-in 0.1s;
      position: absolute;
      right: -100%;
      opacity: 0;
      top: 0;
      bottom: 0;
      width: 140px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 15px 10% 0;

      .list-card-detail {
        width: 120px;
        li {
          font-family: Verdana, sans-serif;
          font-size: 15px;
          font-weight: 300;
          margin-bottom: 10px;
          line-height: 1.2;
          word-spacing: 1px;
        }
      }

      p {
        text-align: center;
        padding: 0 5px;
        color: #666;
        font-weight: 300;
        font-size: 5px;
      }

      .view-detail {
        background: #65bcb6;
        padding: 5px 10px;
        margin: 5px 0 0 0;
        border-radius: 0px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        vertical-align: middle;
        &:hover {
          background: darken(#65bcb6, 10%);
        }
        h4 {
          margin: 0;
          color: white;
          pading: 5px;
          i {
            margin-right: 2px;
          }
        }
      }
    }
  }
}

@keyframes slide-in {
  0% {
    transform: rotateY(-90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
</style>
