<template>
  <div class="services-page">
    <!-- <div class="dropdown">
      <CpnDropdown
        label="Chọn Dịch Vụ"
        :listItems="services"
        svName="selectedService"
        color="#65bcb6"
        v-on:selectedService="selectedService"
      />
    </div>
    <div class="services-container">
      <div class="empty" v-if="selectedSV == null"></div>
      <div class="hsds" v-if="selectedSV === 0">
        <HDSD />
      </div>
      <div class="csbh" v-if="selectedSV === 1">
        <img src="@/assets/img/chinh-sasch-bao-hanh.jpg" />
      </div>
      <div class="tool-kc" v-if="selectedSV === 2">
        <tool-page></tool-page>
      </div>
    </div> -->
    <h1 id="page-title">Chọn Dịch Vụ</h1>
    <div class="menu">
      <ul>
        <li @click="goto(`HDSD`)">
          <div class="sv-image">
            <img src="@/assets/img/ABS-SSM02.jpg" alt="" />
          </div>
          <div class="sv-label">
            <p class="label-text-1">Hướng dẫn</p>
            <p class="label-text-2">Sử dụng máy</p>
            <!-- <p class="label-text-3">Model ABS - SSM02</p> -->
          </div>
        </li>
        <li @click="goto(`InsurancePage`)">
          <div class="sv-image">
            <img src="@/assets/img/chinh-sasch-bao-hanh.jpg" alt="" />
          </div>
          <div class="sv-label">
            <p class="label-text-1">Chính sách</p>
            <p class="label-text-2">Bảo hành</p>
          </div>
        </li>
        <li @click="goto(`ToolPage`)">
          <div class="sv-image">
            <img src="@/assets/img/tool-text2stl.png" alt="" />
          </div>
          <div class="sv-label">
            <p class="label-text-1">Công cụ</p>
            <p class="label-text-2">Viết chữ 3D</p>
            <p class="label-text-1">Lên mặt bánh</p>
          </div>
        </li>
        <li>
          <a
            href="https://www.baizhongyun.cn/home/buildingonline-stamp"
            target="_blank"
          >
            <div class="sv-image">
              <img src="@/assets/img/cake-raw.png" alt="" />
            </div>
            <div class="sv-label">
              <p class="label-text-1">Công cụ</p>
              <p class="label-text-2">Tạo hình vẽ</p>
              <!-- <p class="label-text-1">Lên mặt bánh</p> -->
            </div></a
          >
        </li>
        <li @click="goto(`ColorPalettePage`)">
          <div class="sv-image">
            <img src="@/assets/img/color-palette.png" alt="" />
          </div>
          <div class="sv-label">
            <p class="label-text-1">Tra cứu</p>
            <p class="label-text-2">Bảng màu</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesPage",
  components: {},
  beforeMount() {
    if (this.$route.query.ssv) this.selectedSV = Number(this.$route.query.ssv);
    else this.selectedSV = null;
  },
  data() {
    return {
      services: [
        "Hướng Dẫn Sử Dụng Máy",
        "Chính Sách Bảo Hành",
        "Tool Khắc Chữ 3D Lên Mặt Bánh",
      ],
      selectedSV: null,
    };
  },
  methods: {
    selectedService(ind) {
      this.selectedSV = ind;
    },
    goto(_ssv) {
      this.$router.push({ name: _ssv });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  width: 100% !important;
  height: 100% !important;
  text-decoration: none;
  gap: 8px;
}
.services-page {
  padding-top: 80px;
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;

  #page-title {
    color: #6abcb6;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
  }

  .menu {
    flex-direction: column;
    position: relative;
    max-width: 100vw;
    min-height: 50vh;
    padding-top: 50px;
    margin-left: -15vw;
    align-items: center;
    justify-content: space-between;
    display: flex;

    ul {
      position: relative;
      list-style: none;
      width: 100%;
      min-width: 75vw;
      padding-left: 15vw;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .label-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-decoration: none;
      }

      li {
        height: 200px;
        width: 300px;
        min-width: 200px;
        margin: 0 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 10px;
        gap: 8px;
        margin-bottom: 2rem;

        .sv-image {
          display: flex;
          align-items: center;
          flex-shrink: 0;

          img {
            height: 200px;
            width: 150px;
            border-radius: 10px;
            flex-shrink: 0;
          }
        }

        .sv-label {
          align-self: flex-start;
          width: 100%;

          p {
            color: #26787c;
            margin: 5px 0;
          }

          .label-text-1 {
            font-size: 12px;
            font-weight: 500;
          }

          .label-text-2 {
            font-size: 20px;
            font-weight: 600;
          }

          .label-text-3 {
            font-size: 10px;
            font-weight: 500;
            padding-left: 30px;
          }
        }
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }

      li:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }
  }

  // .dropdown {
  //   position: relative;
  //   align-items: center;
  //   justify-content: center;
  //   display: flex;
  //   width: 100vw;
  //   padding: 50px 10px 10px 10px;
  //   margin: 10px 50px;
  // }

  // .services-container {
  //   background-color: #fff;
  //   display: flex;
  //   width: 100vw;

  //   .empty {
  //     height: 500px;
  //   }

  //   .hdsd {
  //     height: 100vh;
  //     width: 100vw;
  //     align-items: center;
  //     justify-content: flex-start;
  //   }

  //   .csbh {
  //     height: auto;
  //     width: 100vw;
  //     background-color: #fff;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     padding: 100px 0;

  //     img {
  //       width: auto;
  //       height: 90vh;
  //     }
  //   }
  // }
}
</style>
