<template>
  <div
    class="flex flex-col justify-start items-center w-screen h-fit my-10 relative"
  >
    <div class="w-[1500px] h-fit overflow-y-auto">
      <div
        class="w-full h-fit justify-start items-center mb-4 gap-3 flex flex-row flex-wrap"
      >
        <button
          class="w-fit h-fit px-3 py-2 bg-abaso text-white font-bold rounded-[10px]"
          @click="() => onRefesh()"
        >
          Refesh
        </button>
        <button
          @click="this.showAddColor = true"
          class="w-fit h-fit px-3 py-2 bg-[#3c9da1] text-white font-bold rounded-[10px]"
        >
          Thêm màu mới
        </button>
        <div class="flex flex-row justify-center items-center gap-3">
          <input
            type="text"
            class="w-50 h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
            placeholder="Tìm theo tên"
            v-model="inputSearch"
          />
          <button
            class="w-fit h-fit px-3 py-2 bg-[#65bcb6] text-white font-bold rounded-[10px]"
            @click="onSearchByName()"
          >
            Tìm
          </button>
        </div>
      </div>

      <div class="w-full h-fit">
        <div
          class="grid grid-cols-12 w-full h-fit bg-[#65bcb6] rounded-t-[10px] border-solid border-[1px] border-b-abaso"
        >
          <div class="col-span-1 px-4 py-3 font-medium text-white">Mã code</div>
          <div class="col-span-2 px-4 py-3 font-medium text-white">
            Tên Tiếng Việt
          </div>
          <div class="col-span-2 px-4 py-3 font-medium text-white">
            Tên Tiếng Anh
          </div>
          <div class="col-span-2 px-4 py-3 font-medium text-white">Ảnh</div>
          <div class="col-span-1 px-4 py-3 font-medium text-white">Giá gốc</div>
          <div class="col-span-1 px-4 py-3 font-medium text-white">Giá bán</div>
          <div class="col-span-2 px-4 py-3 font-medium text-white">Ghi chú</div>
          <div class="col-span-1 px-4 py-3 font-medium text-white">
            Hành động
          </div>
        </div>
        <div
          v-for="(color, index) in listColorPalettePagination"
          :key="index"
          class="grid grid-cols-12 w-full h-fit"
        >
          <div
            class="col-span-1 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ color.code }}
          </div>
          <div
            class="col-span-2 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ color.vi }}
          </div>
          <div
            class="col-span-2 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ color.eng }}
          </div>
          <div
            class="col-span-2 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            <img :src="color.image" alt="" class="w-full h-auto" />
          </div>
          <div
            class="col-span-1 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ color.original_price }}
          </div>
          <div
            class="col-span-1 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ color.sale_price }}
          </div>
          <div
            class="col-span-2 px-4 py-3 border-solid border-[1px] border-b-abaso"
          >
            {{ color.note }}
          </div>
          <div
            class="col-span-1 px-4 py-3 border-solid border-[1px] border-b-abaso gap-3 flex flex-row flex-wrap"
          >
            <span
              class="text-abaso font-bold cursor-pointer"
              @click="() => loadColorData(color.id)"
              >Sửa</span
            >
            <span
              class="text-red-500 font-bold cursor-pointer"
              @click="() => removeColor(color.id)"
              >Xóa</span
            >
          </div>
        </div>
      </div>

      <div class="w-full h-fit mt-2">
        <div
          class="w-fit h-fit ml-auto flex flex-row justify-center items-center gap-2 inputNumber"
        >
          <button
            :class="[
              currentPage === 1
                ? 'bg-white text-gray-500'
                : 'bg-[#45be8d] text-white',
              'px-2 py-1 rounded-l-lg',
            ]"
            :disabled="currentPage === 1"
            @click="async () => handleCurrentPageChange(currentPage - 1)"
          >
            Prev
          </button>
          <input
            class="w-[64px] h-full p-1 text-center text-base text-abaso border solid border-abaso/80 rounded-lg font-semibold"
            type="number"
            :value="currentPage"
            @change="async (e) => handleCurrentPageChange(e.target.value)"
          />
          <span class="text-abaso font-semibold">{{ `/${totalPage}` }}</span>
          <button
            :class="[
              currentPage === totalPage
                ? 'bg-white text-gray-500'
                : 'bg-[#45be8d] text-white',
              'px-3 py-1 rounded-r-lg',
            ]"
            :disabled="currentPage === totalPage"
            @click="async () => handleCurrentPageChange(currentPage + 1)"
          >
            Next
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="inProgress"
      class="w-screen h-screen bg-[#00000050] fixed top-0 left-0 flex justify-center items-center z-20"
    >
      <p class="w-fit h-fit px-10 py-5 bg-white rounded-[10px] drop-shadow-sm">
        Đang xử lý
      </p>
    </div>
    <div
      v-if="this.showAddColor"
      class="w-screen h-screen fixed left-0 top-0 bg-[#00000050] flex justify-center items-center"
    >
      <div
        class="w-[640px] h-fit py-8 px-10 overflow-auto rounded-xl bg-white drop-shadow-sm flex flex-col justify-center items-center gap-2"
      >
        <span class="w-full text-center text-2xl text-abaso font-bold mb-6"
          >Thêm màu mới</span
        >
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Mã code"
          v-model="this.inputCode"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Tên Tiếng Việt"
          v-model="this.inputVi"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Tên Tiếng Anh"
          v-model="this.inputEng"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Giá gốc (chưa tính sale)"
          v-model="this.inputOriginalPrice"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Giá bán (đã tính sale)"
          v-model="this.inputSalePrice"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Ghi chú"
          v-model="this.inputNote"
        />
        <div class="pick-img">
          <button class="btnAddImage" @click="onChooseImage">
            <font-awesome-icon clas="icon-add" icon="file" />
            <p>Thêm ảnh</p>
          </button>
          <input
            type="file"
            accept="image/*"
            ref="refUploadImg"
            v-show="false"
            @change="pickColorImage($event)"
          />
          <img class="img-preview" :src="imgUrl" alt="" />
        </div>
        <div
          class="w-full h-fit flex justify-center items-center flex-row flex-wrap gap-3 mt-6"
        >
          <button
            class="w-fit h-fit px-3 py-2 bg-red-500 text-white font-bold rounded-[10px]"
            @click="this.showAddColor = false"
          >
            Hủy</button
          ><button
            @click="() => onAddNewColor()"
            class="w-fit h-fit px-3 py-2 bg-abaso text-white font-bold rounded-[10px]"
          >
            Thêm
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="this.showEditColor"
      class="w-screen h-screen fixed left-0 top-0 bg-[#00000050] flex justify-center items-center"
    >
      <div
        class="w-[640px] h-fit py-8 px-10 overflow-auto rounded-xl bg-white drop-shadow-sm flex flex-col justify-center items-center gap-2"
      >
        <span class="w-full text-center text-2xl text-abaso font-bold mb-6"
          >Sửa thông tin màu</span
        >
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Mã code"
          v-model="this.inputCode"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Tên Tiếng Việt"
          v-model="this.inputVi"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Tên Tiếng Anh"
          v-model="this.inputEng"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Giá gốc (chưa tính sale)"
          v-model="this.inputOriginalPrice"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Giá bán (đã tính sale)"
          v-model="this.inputSalePrice"
        />
        <input
          type="text"
          class="w-full h-fit px-3 py-2 border-abaso border-solid rounded-[10px] border-2"
          placeholder="Ghi chú"
          v-model="this.inputNote"
        />
        <div class="pick-img">
          <button class="btnAddImage" @click="onChooseImage">
            <font-awesome-icon clas="icon-add" icon="file" />
            <p>Thêm ảnh</p>
          </button>
          <input
            type="file"
            accept="image/*"
            ref="refUploadImg"
            v-show="false"
            @change="pickColorImage($event)"
          />
          <img
            class="img-preview"
            :src="imgUrl.length === 0 ? selectedColorImagePath : imgUrl"
            alt=""
          />
        </div>
        <div
          class="w-full h-fit flex justify-center items-center flex-row flex-wrap gap-3 mt-6"
        >
          <button
            class="w-fit h-fit px-3 py-2 bg-red-500 text-white font-bold rounded-[10px]"
            @click="this.showEditColor = false"
          >
            Hủy</button
          ><button
            @click="() => onEditColor()"
            class="w-fit h-fit px-3 py-2 bg-abaso text-white font-bold rounded-[10px]"
          >
            Xác nhận
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseUtl from "@/utilities/firebase";
import stringUtils from "@/utilities/stringUtils";

export default {
  name: "AdminColorPalette",
  async beforeMount() {
    this.totalRecord = await firebaseUtl.countDocsCollection("color-palette");
    this.totalPage =
      this.totalRecord < 10
        ? 1
        : Math.floor(this.totalRecord / 10) === 0
        ? this.totalRecord / 10
        : (this.totalRecord % 10) + 1;
    this.listColorPalette = await firebaseUtl.getDbCollection("color-palette");
    this.listColorPalette = this.listColorPalette.sort(function (a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
    this.listColorPalettePagination = this.listColorPalette.slice(0, 10);
    console.log(this.listColorPalette);
  },
  data() {
    return {
      totalRecord: 0,
      totalPage: 1,
      currentPage: 1,
      inProgress: false,
      showAddColor: false,
      showEditColor: false,
      selectedColorId: "",
      listColorPalette: [],
      listColorPalettePagination: [],
      oldImageId: "",
      imgUrl: "",
      imgFbUrl: "",
      imgFile: null,
      inputCode: "",
      inputVi: "",
      inputEng: "",
      inputOriginalPrice: "",
      inputSalePrice: "",
      inputNote: "",
      inputSearch: "",
      selectedColorImagePath: "",
    };
  },
  methods: {
    formatDate(_date) {
      return stringUtils.formatDate(_date);
    },
    async removeColor(id) {
      try {
        if (confirm("Xác nhận Xóa?")) {
          this.inProgress = true;
          //delete old image
          let curretImageId = this.listColorPalette.filter(
            (x) => x.id === id
          )[0].imageId;
          let delRes = firebaseUtl.removeFileFromFilebase(curretImageId);
          if (!delRes) {
            alert("Xóa màu thất bại.");
            this.inProgress = false;
            return;
          }
          await firebaseUtl.removeRecord("color-palette", id);
          alert("Đã xóa");
          this.listColorPalette = await firebaseUtl.getDbCollection(
            "color-palette"
          );
          this.listColorPalette = this.listColorPalette.sort(function (a, b) {
            if (a.code < b.code) {
              return -1;
            }
            if (a.code > b.code) {
              return 1;
            }
            return 0;
          });
          this.listColorPalettePagination = this.listColorPalette.splice(
            this.currentPage * 10,
            10
          );
          this.inProgress = false;
        }
      } catch (error) {
        alert("Xóa màu thất bại.");
        this.inProgress = false;
      }
    },
    async onRefesh() {
      this.totalRecord = await firebaseUtl.countDocsCollection("color-palette");
      this.totalPage =
        this.totalRecord < 10
          ? 1
          : Math.floor(this.totalRecord / 10) === 0
          ? this.totalRecord / 10
          : (this.totalRecord % 10) + 1;
      this.listColorPalette = await firebaseUtl.getDbCollection(
        "color-palette"
      );
      this.listColorPalette = this.listColorPalette.sort(function (a, b) {
        if (a.code < b.code) {
          return -1;
        }
        if (a.code > b.code) {
          return 1;
        }
        return 0;
      });
      this.listColorPalettePagination = this.listColorPalette.slice(0, 10);
    },
    async loadColorData(_id) {
      this.inProgress = true;
      let colorData = this.listColorPalette.filter((x) => x.id === _id)[0];
      this.inputCode = colorData.code;
      this.inputVi = colorData.vi;
      this.inputEng = colorData.eng;
      this.inputOriginalPrice = colorData.original_price;
      this.inputSalePrice = colorData.sale_price;
      this.inputNote = colorData.note;
      this.selectedColorId = _id;
      this.inProgress = false;
      this.showEditColor = true;
      this.selectedColorImagePath = colorData.image;
      this.oldImageId = colorData.imageId;
      this.oldImagePath = colorData.image;
    },
    async editColor() {
      try {
        this.showEditColor = false;
        this.inProgress = true;
        let fbImagePath = this.oldImagePath;
        let fbImageId = this.oldImageId;

        //delete old image
        if (this.imgFile) {
          let delRes = firebaseUtl.removeFileFromFilebase(this.oldImageId);
          if (!delRes) {
            alert("Sửa thông tin màu thất bại.");
            this.inProgress = false;
            return;
          }
          fbImageId = await firebaseUtl.uploadFileToFirebase(this.imgFile);
          if (fbImageId === null) {
            alert("Sửa thông tin màu thất bại. Vui lòng thử lại sau.");
            this.inProgress = false;
            return;
          } else {
            fbImagePath = await firebaseUtl.getFriebaseFileUrl(fbImageId);
          }
        }

        //post new color
        let newData = {
          code: this.inputCode,
          vi: this.inputVi,
          eng: this.inputEng,
          imageId: fbImageId,
          image: fbImagePath,
          original_price: this.inputOriginalPrice,
          sale_price: this.inputSalePrice,
          note: this.inputNote,
        };

        console.log(this.selectedColorId, newData);

        await firebaseUtl.editRecord(
          "color-palette",
          this.selectedColorId,
          newData
        );

        //on success
        // alert("Sửa thông tin mới thành công.");
        this.listColorPalette = await firebaseUtl.getDbCollection(
          "color-palette"
        );
        this.inProgress = false;
        return true;
      } catch (error) {
        console.log(error);
        this.inProgress = false;
        alert("Sửa thông tin mới thất bại.");
        return false;
      }
    },
    async onEditColor() {
      try {
        this.showEditColor = false;
        let ecRes = await this.editColor();
        if (!ecRes) {
          this.inProgress = false;
          return;
        }
        this.inProgress = true;
        this.inputNewStoreAddress = "";
        this.inputNewStoreCode = "";
        this.inputNewStorePhone = "";
        this.inputNewStoreName = "";
        this.selectedColorId = "";
        this.imgFile = null;
        this.imgUrl = "";
        alert("Thay đổi thông tin màu thành công!");
        this.listColorPalette = await firebaseUtl.getDbCollection(
          "color-palette"
        );
        this.listColorPalette = this.listColorPalette.sort(function (a, b) {
          if (a.code < b.code) {
            return -1;
          }
          if (a.code > b.code) {
            return 1;
          }
          return 0;
        });
        this.listColorPalettePagination = this.listColorPalette.splice(
          this.currentPage * 10,
          10
        );
        this.inProgress = false;
      } catch (e) {
        alert("Thay đổi thông tin màu thất bại!");
        this.inProgress = false;
      }
    },

    pickColorImage(event) {
      this.imgFile = event.target.files[0];
      this.imgUrl = URL.createObjectURL(event.target.files[0]);
    },
    onChooseImage() {
      this.$refs.refUploadImg.click();
    },

    async onAddNewColor() {
      try {
        this.showAddColor = false;
        this.inProgress = true;
        //uploading image
        let fbImageId = await firebaseUtl.uploadFileToFirebase(this.imgFile);
        let fbImagePath = "";
        if (fbImageId === null) {
          alert("Tạo màu mới thất bại. Vui lòng thử lại sau.");
          this.inProgress = false;
          return;
        } else {
          fbImagePath = await firebaseUtl.getFriebaseFileUrl(fbImageId);
        }
        //post new color
        let newData = {
          code: this.inputCode,
          vi: this.inputVi,
          eng: this.inputEng,
          imageId: fbImageId,
          image: fbImagePath,
          original_price: this.inputOriginalPrice,
          sale_price: this.inputSalePrice,
          note: this.inputNote,
        };

        let dateTime = new Date();
        let randomChar = (Math.random() + 1).toString(36).substring(7);
        let id = `color_${dateTime.getTime()}_${randomChar}`;

        await firebaseUtl.editRecord("color-palette", id, newData);

        //on success
        this.imgFile = null;
        this.imgUrl = "";
        alert("Tạo màu mới thành công.");
        this.listColorPalette = await firebaseUtl.getDbCollection(
          "color-palette"
        );
        this.listColorPalette = this.listColorPalette.sort(function (a, b) {
          if (a.code < b.code) {
            return -1;
          }
          if (a.code > b.code) {
            return 1;
          }
          return 0;
        });
        this.listColorPalettePagination = this.listColorPalette.splice(
          this.currentPage * 10,
          10
        );
        this.inProgress = false;
      } catch (error) {
        alert("Tạo màu mới thất bại. Vui lòng thử lại sau.");
        this.inProgress = false;
        return;
      }
    },
    async onSearchByName() {
      this.listColorPalette = await firebaseUtl.getDbCollection(
        "color-palette"
      );
      this.listColorPalette = this.listColorPalette.filter(
        (x) =>
          x.vi.toLowerCase().includes(this.inputSearch.toLowerCase()) ||
          x.eng.toLowerCase().includes(this.inputSearch.toLowerCase())
      );
    },
    async handleCurrentPageChange(value) {
      console.log(value);
      this.currentPage = value;
      this.listColorPalette = await firebaseUtl.getDbCollection(
        "color-palette"
      );
      this.listColorPalette = this.listColorPalette.sort(function (a, b) {
        if (a.code < b.code) {
          return -1;
        }
        if (a.code > b.code) {
          return 1;
        }
        return 0;
      });
      this.listColorPalettePagination = this.listColorPalette.splice(
        this.currentPage * 10,
        10
      );
      console.log(this.listColorPalettePagination);
    },
  },
};
</script>

<style lang="scss" scoped>
.pick-img {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .img-preview {
    height: 50px;
    margin: 0px 20px;
  }
}
.btnAddImage {
  color: #26787c;
  border: 2px solid #26787c;
  border-radius: 15px;
  padding: 3px;

  &:hover {
    color: #ffffff;
    background-color: #26787c;
  }

  &:active {
    box-shadow: inset -2px -2px 3px rgba(255, 255, 255, 0.6),
      inset 2px 2px 3px rgba(0, 0, 0, 0.6);
  }
}

.inputNumber {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
