<template>
  <!-- <iframe src="http://192.168.1.19:4200/en-us/generator"></iframe> -->
  <div class="tool-page">
    <h1>Công Cụ Viết Chữ 3D Lên Mặt Bánh</h1>
    <iframe src="https://text2stl.mestres.fr/en-us/generator"></iframe>
  </div>
</template>

<script>
export default {
  name: "ToolPage",
};
</script>

<style lang="scss" scoped>
.tool-page {
  padding-top: 80px;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    color: #6abcb6;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1rem;
  }

  iframe {
    height: 100vh;
    width: 100vw;
  }
}
</style>