<template>
  <div class="flex flex-col pt-[10vh] justify-start items-center">
    <div
      class="w-[90vw] lg:w-[800px] min-h-screen flex flex-col justify-start items-center"
    >
      <p class="w-full text-center font-bold text-2xl text-abaso">
        Tra Cứu Bảo Hành
      </p>
      <p class="mt-3 text-center">
        Quý khách vui lòng nhập thông tin để tra cứu bảo hành
      </p>
      <div class="mt-5 w-[90%] flex flex-col justify-center items-center">
        <div class="w-full mb-3">
          <p class="text-[18px] text-abaso font-bold px-2 py-1">
            Số điện thoại
          </p>
          <input
            class="w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2"
            type="text"
            v-model="phone"
          />
        </div>
        <div class="w-full mb-3">
          <p class="text-[18px] text-abaso font-bold px-2 py-1">
            Seri sản phẩm
          </p>
          <input
            class="w-full py-2 px-3 border-abaso border-solid rounded-[10px] border-2"
            type="text"
            v-model="seriNumber"
          />
        </div>
        <button class="mt-3 rounded-[10px] bg-abaso px-8 py-2" @click="onCheck">
          <p class="text-white font-bold">Tra cứu</p>
        </button>

        <p
          v-if="product != null"
          class="w-full text-center font-bold text-2xl text-abaso mt-10"
        >
          Thông tin bảo hành của bạn
        </p>

        <div
          v-if="product != null"
          class="flex flex-row flex-wrap justify-center lg:justify-start items-start h-fit w-full p-3 mt-5 bg-[#CEEDD8] rounded-[10px]"
        >
          <img
            class="w-[300px] lg:w-[220px] h-auto mb-4 lg:mr-4 rounded-[10px] cursor-pointer"
            :src="productImg"
            alt=""
            @click="gotoProductDetail(baohanh.product_id)"
          />

          <div class="w-[300px] lg:w-full h-fit flex flex-col">
            <div class="flex flex-row w-full h-fit items-center justify-start">
              <span>Tên khách hàng:</span>
              <span class="ml-3 font-semibold">{{
                baohanh && baohanh.customer_name
              }}</span>
            </div>
            <div
              class="flex flex-row w-full h-fit items-center justify-start mt-2"
            >
              <span>Số điện thoại:</span>
              <span class="ml-3 font-semibold">{{
                baohanh && baohanh.phone
              }}</span>
            </div>
            <div
              class="flex flex-row w-full h-fit items-center justify-start mt-2"
            >
              <span>Sản phẩm:</span>
              <router-link
                class="no-underline cursor-pointer"
                :to="{
                  name: 'ProductDetail',
                  params: { productId: product.id },
                }"
                ><span class="ml-3 font-bold text-[#277881]">{{
                  baohanh && baohanh.product_name
                }}</span></router-link
              >
            </div>
            <div
              class="flex flex-row w-full h-fit items-center justify-start mt-2"
            >
              <span>Ngày mua hàng: </span>
              <span class="ml-3 font-semibold">{{
                baohanh && formatDate(baohanh.start_date.toDate())
              }}</span>
            </div>
            <div
              class="flex flex-row w-full h-fit items-center justify-start mt-2"
            >
              <span>Nơi mua hàng: </span>
              <span class="ml-3 font-semibold">{{
                baohanh && baohanh.store_name
              }}</span>
            </div>
            <div
              class="flex flex-row w-full h-fit items-center justify-start mt-2"
            >
              <span>Trạng thái bảo hành: </span>
              <span class="ml-3 font-semibold">{{
                baohanh && !baohanh.approval
                  ? "Chưa kích hoạt bảo hành"
                  : "Còn bảo hành"
              }}</span>
            </div>
            <div
              v-if="baohanh && baohanh.approval"
              class="flex flex-row w-full h-fit items-center justify-start mt-2"
            >
              <span>Ngày hết hạn bảo hành: </span>
              <span class="ml-3 font-semibold">{{
                baohanh && formatDate(baohanh.end_date.toDate())
              }}</span>
            </div>
          </div>
        </div>

        <p
          v-if="product != null"
          class="w-full text-center font-bold text-2xl text-abaso mt-10"
        >
          Thông tin Gửi bảo hành của sản phẩm
        </p>

        <div
          v-if="product != null"
          class="flex flex-row flex-wrap justify-center lg:justify-start items-start h-fit w-full mb-8 p-3 mt-5 bg-[#CEEDD8] rounded-[10px]"
        >
          <span v-if="!guiBH"
            >Sản phẩm của bạn đă hoàn thành sửa chữa hoặc chưa gửi bảo hành. Vui
            lòng liên hệ cửa hàng để biết thêm thông tin!</span
          >
          <div v-if="guiBH !== null" class="w-full h-fit flex flex-col gap-2">
            <div class="flex flex-row w-full h-fit items-center justify-start">
              <span>Ngày gửi bảo hành: </span>
              <span class="ml-3 font-semibold">{{
                formatDate(guiBH?.ngay_gui?.toDate())
              }}</span>
            </div>  
            <div
              class="flex flex-row w-full h-fit items-center justify-start mt-2"
            >
              <span>Lý do bảo hành: </span>
              <span class="ml-3 font-semibold">{{ guiBH?.client_note }}</span>
            </div>
            <span class="mt-2">Quy trình xử lý bảo hành: </span>
            <div
              class="flex flex-row w-full h-fit items-center justify-start mt-1"
            >
              <span class="ml-2">1. Tiếp nhận: </span>
              <span
                :class="[
                  'ml-3 font-semibold',
                  guiBH?.tiep_nhan ? 'text-green-500' : 'text-yellow-500',
                ]"
                >{{ guiBH?.tiep_nhan ? "Hoàn thành" : "Chờ xử lý" }}</span
              >
            </div>
            <div
              class="flex flex-row w-full h-fit items-center justify-start mt-1"
            >
              <span class="ml-2">2. Kiểm tra: </span>
              <span
                :class="[
                  'ml-3 font-semibold',
                  guiBH?.kiem_tra ? 'text-green-500' : 'text-yellow-500',
                ]"
                >{{ guiBH?.kiem_tra ? "Hoàn thành" : "Chờ xử lý" }}</span
              >
            </div>
            <div
              class="flex flex-row w-full h-fit items-center justify-start mt-1"
            >
              <span class="ml-2">3. Sửa chữa: </span>
              <span
                :class="[
                  'ml-3 font-semibold',
                  guiBH?.sua_chua ? 'text-green-500' : 'text-yellow-500',
                ]"
                >{{ guiBH?.sua_chua ? "Hoàn thành" : "Chờ xử lý" }}</span
              >
            </div>
            <div
              class="flex flex-row w-full h-fit items-center justify-start mt-1"
            >
              <span class="ml-2">4. Bàn giao: </span>
              <span
                :class="[
                  'ml-3 font-semibold',
                  guiBH?.ban_giao ? 'text-green-500' : 'text-yellow-500',
                ]"
                >{{
                  guiBH?.ban_giao
                    ? "Vui lòng liên hệ nơi gửi bảo hành"
                    : "Chờ xử lý"
                }}</span
              >
            </div>
            <div
              class="flex flex-row w-full h-fit items-center justify-start mt-2"
            >
              <span>Ngày dự kiến trả bảo hành: </span>
              <span class="ml-3 font-semibold">{{
                formatDate(guiBH?.ngay_hoan_thanh?.toDate())
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="inProgress"
      class="w-screen h-screen bg-[#00000050] fixed top-0 left-0 flex justify-center items-center z-20"
    >
      <p class="w-fit h-fit px-10 py-5 bg-white rounded-[10px] drop-shadow-sm">
        Đang kiểm tra
      </p>
    </div>
  </div>
</template>

<script>
import firebaseUtils from "@/utilities/firebase";
import stringUtils from "@/utilities/stringUtils";

export default {
  name: "KtBaoHanhPage",
  async beforeMount() {
    // this.getBhBySeri("1223456");
  },
  data() {
    return {
      seriNumber: "",
      phone: "",
      inProgress: false,
      baohanh: null,
      product: null,
      productImg: null,
      guiBH: null,
    };
  },
  methods: {
    formatDate(_date) {
      return stringUtils.formatDate(_date);
    },
    async getBhBySeri(_seri, _phone) {
      try {
        firebaseUtils.getBhBySeri(_seri, _phone).then((res) => {
          if (res == null)
            alert("Rất tiếc! Không tìm thấy thông tin của qúy khách.");
          else {
            this.baohanh = res;

            firebaseUtils.getDbById("product", res.product_id).then((pres) => {
              this.product = pres;
              firebaseUtils
                .getFriebaseFileUrl(this.product.img)
                .then((rimg) => {
                  this.productImg = rimg;
                });
              console.log(pres);
            });
          }
        });
        firebaseUtils.traCuuGuiBaoHanh(_phone, _seri).then((res) => {
          if (Array.isArray(res) && res.length == 1) {
            this.guiBH = res[0];
          }
        });
      } catch (error) {
        alert("Rất tiếc! Không tìm thấy thông tin của qúy khách.");
      }
    },
    async onCheck() {
      if (this.seriNumber.length === 0 || this.phone.length < 10)
        alert("Quý khách vui lòng nhập chính xác thông tin!");
      else {
        this.inProgress = true;
        await this.getBhBySeri(this.seriNumber, this.phone);
        this.inProgress = false;
      }
    },
    gotoProductDetail(_productId) {
      this.$router.push({
        name: "ProductDetail",
        params: { productId: _productId },
      });
    },
  },
};
</script>

<style></style>
